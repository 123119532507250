import React from 'react';
import Container from 'react-bootstrap/Container';
import { listCoupons } from '../../services/api';

const Coupons = ({ setProgress }) => {

    const [coupons, setCoupons] = React.useState([]);


    React.useEffect(() => {
        setProgress(true);
        listCoupons({ status: 'ACTIVED' }).then(resp => {
            setCoupons(resp.data.data || []);
            setProgress(false);
        }).catch(err => setProgress(false))

    }, [])

    return (
        <div>
            <Container className='my-6'>
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-6 col-sm-auto d-flex align-items-center pr-0">
                                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0"> Coupons valides</h5>
                            </div>
                        </div>
                    </div>

                    <div className="card-body px-0 pt-0">
                        <div className="dashboard-data-table">
                            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="row mx-1"><div className="col-sm-12 col-md-6 px-3" /><div className="col-sm-12 col-md-6 px-3" /></div><div className="table-responsive"><table className="table table-sm table-dashboard fs--1 data-table border-bottom dataTable no-footer" data-options="{&quot;responsive&quot;:false,&quot;pagingType&quot;:&quot;simple&quot;,&quot;lengthChange&quot;:false,&quot;searching&quot;:false,&quot;pageLength&quot;:8,&quot;columnDefs&quot;:[{&quot;targets&quot;:[0,6],&quot;orderable&quot;:false}],&quot;language&quot;:{&quot;info&quot;:&quot;_START_ to _END_ Items of _TOTAL_ — <a href=\&quot;#!\&quot; class=\&quot;font-weight-semi-bold\&quot;> view all <span class=\&quot;fas fa-angle-right\&quot; data-fa-transform=\&quot;down-1\&quot;></span> </a>&quot;},&quot;buttons&quot;:[&quot;copy&quot;,&quot;excel&quot;]}" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                <thead className="bg-200 text-900">
                                    <tr role="row">
                                        <th className="sort pr-1 align-middle sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="" style={{ width: '200.734px' }}>coupon</th>
                                        <th className="sort pr-1 align-middle sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="" style={{ width: '100.734px' }}>pourcentage</th>
                                        <th className="sort pr-1 align-middle sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="" style={{ width: '100.734px' }}>description</th>
                                        <th className="sort pr-1 align-middle" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="" style={{ width: '200.734px' }}>Status</th>
                                    </tr>
                                </thead>

                                <tbody id="coupons">
                                    {
                                        coupons.map((coupon) => (
                                            <tr className="btn-reveal-trigger odd" role="row">
                                                <th className="align-middle">
                                                    <a href="#" >{coupon?.name}</a>
                                                </th>
                                                <td className="align-middle">{coupon?.amount}%</td>
                                                <td className="align-middle"></td>
                                                <td className="align-middle">
                                                    {coupon?.status}
                                                </td>

                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                            </div>

                            </div>
                        </div>
                    </div>
                </div>

            </Container>
        </div>
    );
};

export default Coupons;