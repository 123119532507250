import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditQuestionModal from '../Modal/EditQuestionModal';
import Spinner from 'react-bootstrap/Spinner';
import { editOrDeleteQuestion } from '../../services/api';

const EditAndDeleteQuestion = ({ subjectID, questionID, consigne, reponse, free, setOpenToast }) => {

    const [open, setOpen] = React.useState(false);
    const [progress, setProgress] = React.useState(false);
    const [modalContent, setModalContent] = React.useState(null);

    const onClose = () => {
        setOpen(false);
    }



    const handlerEditQuestion = editData => {
        setProgress(true);

        editOrDeleteQuestion({ action: 'EDIT', questionID, subjectID, consigne: editData.consigne, response: editData.reponse, free: editData.isFree })
            .then(resp => {
                setProgress(false);
                setOpenToast({
                    open: true,
                    msg: 'Sujet supprimé avec succès',
                    severity: 'success'
                })
                setOpen(false);
            }).catch(err => {
                console.log(err);
                setProgress(false);
                setOpenToast({
                    open: true,
                    msg: 'Erreur lors de la suppression du sujet',
                    severity: 'error'
                })
                setOpen(false);
            })
    }

    const handlerDelete = editData => {
        setProgress(true);

        editOrDeleteQuestion({ action: 'DELETE', questionID, subjectID })
            .then(resp => {
                setProgress(false);
                setOpenToast({
                    open: true,
                    msg: 'Sujet supprimé avec succès',
                    severity: 'success'
                })
            }).catch(err => {
                setProgress(false);
                setOpenToast({
                    open: true,
                    msg: 'Erreur lors de la suppression du sujet',
                    severity: 'error'
                })
            })
    }


    return (
        <div className='d-inline'>
            <EditIcon className='ml-2 mr-2' onClick={e => setOpen(true)} style={{ cursor: 'pointer' }} />
            <DeleteIcon onClick={handlerDelete} style={{ cursor: 'pointer' }} />

            {
                progress === true ? (
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : null
            }

            <EditQuestionModal
                key={questionID}
                isOpen={open}
                onClose={onClose}
                onSave={handlerEditQuestion}
                question={
                    {
                        consigne,
                        reponse,
                        isFree: free
                    }
                }
                progress={progress}
            />
        </div>
    );
};

export default EditAndDeleteQuestion;