import React from 'react';
import Table from 'react-bootstrap/Table';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import PermCameraMicIcon from '@mui/icons-material/PermCameraMic';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';

const AllSubjects = () => {

    return (
        <Table striped bordered hover variant="dark">

            <tbody>
                <tr>
                    <td><HeadphonesIcon className='text-info' style={{ fontSize: '1rem' }} />Compréhension Orale</td>
                    <td>
                        <NavLink to={'/'}>
                            Description de l'épreuve
                        </NavLink>
                    </td>
                    <td>
                        <Button variant="outlined" size="small">
                            Sujets
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td><MenuBookOutlinedIcon className='text-danger' style={{ fontSize: '1rem' }} />Compréhension Ecrite</td>
                    <td>Description de l'épreuve</td>
                    <td>
                        <Button variant="outlined" size="small">
                            Sujets
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td><PermCameraMicIcon className='text-success' style={{ fontSize: '1rem' }} />Expression Orale</td>
                    <td>
                        <NavLink to={'/'}>
                            Description de l'épreuve
                        </NavLink>
                    </td>
                    <td>
                        <Button variant="outlined" size="small">
                            Sujets
                        </Button>
                    </td>
                </tr>
                <tr>
                    <td><KeyboardIcon className='text-warning' style={{ fontSize: '1rem' }} />Expression Orale</td>
                    <td>
                        <NavLink to={'/'}>
                            Description de l'épreuve
                        </NavLink>
                    </td>
                    <td>
                        <Button variant="outlined" size="small">
                            Sujets
                        </Button>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
};

export default AllSubjects;