import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MyToast = (props) => {

    const { openToast, setOpenToast} = props;
    

    let vertical = openToast.vertical || 'top';
    let horizontal = openToast.horizontal || 'center';

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast({
            open: false,
            msg: '',
            severity: ''
        });
    };


    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openToast.open}
            autoHideDuration={openToast.duration || 4000}
            onClose={handleClose}
            key={vertical + horizontal}
        >
            <Alert severity={openToast.severity} sx={{ width: '100%' }}>
                {openToast.msg}
            </Alert>
        </Snackbar>
    );
};

export default MyToast;