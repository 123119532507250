import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';
import MyToast from './MyToast';
import MySpinner from './MySpinner';
import { convertDevice, listOffers, listSubscriptions } from '../services/api';
import QuestionModal from './QuestionModal';
import Spinner from 'react-bootstrap/Spinner';
import CheckIcon from '@mui/icons-material/Check';
import { CheckCircleOutline } from '@mui/icons-material';



const pricingColors = {
    0: 'red',
    1: 'green',
    2: 'blue',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
}

const Pricing = () => {

    const navigate = useNavigate();
    const { user } = useAuth();

    const [inProgress, setinProgress] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });

    //modal
    const [offers, setOffers] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [modalValue, setModalValue] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const [question, setQuestion] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [newOffer, setNewOffer] = React.useState(null);
    const [amountXaf, setAmountXaf] = React.useState({});


    React.useEffect(() => {

        listOffers({ status: 'ACTIVED', skip: 0, limit: 10 })
            .then(offersList => {
                if (offersList && offersList?.data && offersList?.data?.data) {
                    setOffers(offersList?.data?.data);
                }

                setLoading(false);

            }).catch(err => setLoading(false))
    }, [])

    React.useEffect(() => {

        if (modalValue === 'annuler') {
            setinProgress(false);
            setModalValue('');
            setQuestion('');
            setTitle('');
        }
        else if (modalValue === 'accept') {
            setinProgress(false);
            const params = JSON.stringify(newOffer);
            navigate('/abonnement', {
                state: {
                    offer: params
                }
            });
        }

    }, [modalValue]);



    const handlerClick = async (e, offerID, index) => {
        e.preventDefault();

        if (!user) {
            navigate('/connexion/email', { state: { msg: 'Vous devez vous connecter pour souscrire à cet abonnement' } });
            return;
        }

        setinProgress(true);
        setModalValue('');
        setQuestion('');
        setTitle('');
        setShow(false);

        try {

            //récupérer les détails de l'offre
            const offer = offers[index];
            setNewOffer(offer);

            //récupérons la liste des souscriptions actuelles de l'utilisateur
            //const subscriptionsDatas = await listSubscriptions({userID: user.uid, subscriptionStatus: 'ACTIVED'});
            const subscriptionsDatas = await listSubscriptions({ userID: user.uid, subscriptionStatus: 'ACTIVED', count: true });

            if (subscriptionsDatas.data?.data === undefined) {
                setOpenToast({
                    open: true,
                    msg: 'Impossible de souscrire à cette offre pour le moment. Veuillez réessayer ultérieurement',
                    severity: 'error'
                })
                setinProgress(false);
                return;
            }

            const countCurrentUserSubscriptions = subscriptionsDatas?.data?.data;

            if (countCurrentUserSubscriptions !== 0) {
                setTitle('Nouvel abonnement');
                setQuestion('Votre abonnement actuel est toujours en cours. Si vous cliquez sur Continuer, votre abonnement sera mis à jour avec le nouvel abonnement');
                setShow(true);
                if (modalValue === 'annuler') {
                    setinProgress(false);
                    return;
                }
            }
            else {
                setModalValue('accept');
            }

        } catch (error) {
            console.log(error);
            setOpenToast({
                open: true,
                msg: 'Impossible de souscrire à cette offre pour le moment. Veuillez réessayer ultérieurement1',
                severity: 'error'
            })
            setinProgress(false);
            return;
        }

    }


    const formatQty = qte => {
        qte = qte.toString();
        if (qte.length == 1) return `0${qte}`;
        return qte;
    }

    const formatSubject = (descrip, qte) => {
        qte = parseInt(qte);
        let accord = qte > 1 ? 's' : '';
        let sujet = descrip.replace('Epreuve', '');
        return `Sujet${accord} ${sujet}`;
    }

   /*  React.useEffect(() => {

        let tmp = {};
        let current = 0;

        while (current < offers.length) {
            convertDevice({
                amount: offers[current].price,
                from: 'usd',
                to: 'xaf'
            }, rep_amount => {
                if (rep_amount) {
                    tmp = { ...tmp, ...{ [`${offers[current]._id}`]: `(${rep_amount} XAF)` } }
                    current = current + 1;
                }
                else {
                    tmp = { ...tmp, ...{ [`${offers[current]._id}`]: '' } }
                    current = current + 1;
                }
            })
        }

        setAmountXaf(tmp);

    }, [offers])


    React.useEffect(() => {
        console.log(amountXaf);

    }, [amountXaf])

 */

    return (
        <div className='row justify-content-center align-items-center text-center'>
            <Spinner animation="border" role="status" className={`${loading === true ? '' : 'd-none'}`}></Spinner>
            <div className="demo">
                <div className="container">
                    <div className="row justify-content-center align-items-center" id='offers'>

                        {
                            offers.map((offer, index) => (
                                <div className="col-md-4 col-sm-6">

                                    <div className={`pricingTable ${pricingColors[index]}`}  >
                                        <div className="pricingTable-header">
                                            <div className="price-value">
                                                <span className="amount">${offer.price}</span> <span></span>
                                                <span className="duration">Valide {offer.duration} jours</span>
                                            </div>
                                            <h3 className="title">{offer.name}</h3>
                                        </div>
                                        <ul className="pricing-content px-3">
                                            {
                                                offer.subjectsTypes.map(subType => (
                                                    <li><CheckCircleOutline className='mr-1' color='secondary' /> {formatQty(subType.quantity)} {formatSubject(subType.subjectType.description, subType.quantity)} </li>
                                                ))
                                            }

                                            <li><CheckCircleOutline className='mr-1' color='secondary' />Sujets d'actualités d'expression orale</li>
                                            <li><CheckCircleOutline className='mr-1' color='secondary' />Sujets d'actualités d'expression écrite</li>
                                        </ul>

                                        <div className="pricingTable-signup">
                                            <NavLink onClick={(e) => handlerClick(e, offer._id, index)}>M'abonner</NavLink>
                                        </div>

                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>

            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />

            <QuestionModal
                show={show}
                setShow={setShow}
                title={title}
                question={question}
                setModalValue={setModalValue}
            />

        </div>

    );
};

export default Pricing;