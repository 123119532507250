import React from 'react';
import { listSubscriptions, listUsers } from '../../services/api';

const Dashbord = ({ setProgress }) => {


    const [countUser, setCountUser] = React.useState('');
    const [countSubscription, setCountSubscription] = React.useState('');
    const [countActiveSubscription, setCountActiveSubscription] = React.useState('');


    React.useEffect(() => {
        setProgress(true);

        listUsers({ count: true }).then(resp => {
            setCountUser(resp.data.data || 0);

            listSubscriptions({ subscriptionStatus: 'ACTIVED', count: true })
                .then((resp1) => {
                    setCountActiveSubscription(resp1.data.data || 0);

                    listSubscriptions({ count: true })
                        .then((resp1) => {
                            setCountSubscription(resp1.data.data || 0);
                            setProgress(false);
                        })
                        .catch(err => {
                            setProgress(false);
                        })
                })
                .catch(err => {
                    setProgress(false);
                })
        })
            .catch(err => {
                setProgress(false);
            })

    }, [])




    const formatResult = valeur => {
        valeur = valeur + '';
        if(valeur.length == 1) return `0${valeur}`;

        return valeur;
    }

    return (

        <div className='d-flex justify-content-center mt-7 mb-7 mb-md-10 '>
            <div className="card-deck col-12 col-sm-12 col-md-8">
                <div className="card mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
                    <div className="bg-holder bg-card" style={{ backgroundImage: 'url(assets/img/illustrations/corner-1.png)' }}>
                    </div>
                    <div className="card-body position-relative">
                        <h6>Utilisateurs<span className="badge badge-soft-warning rounded-capsule ml-2"></span></h6>
                        <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif text-warning">{formatResult(countUser)}</div>
                    </div>
                </div>
                <div className="card mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
                    <div className="bg-holder bg-card" style={{ backgroundImage: 'url(assets/img/illustrations/corner-2.png)' }}>
                    </div>
                    <div className="card-body position-relative">
                        <h6>Abonnements<span className="badge badge-soft-info rounded-capsule ml-2"></span></h6>
                        <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif text-info">{formatResult(countSubscription)}</div>
                    </div>
                </div>
                <div className="card mb-3 overflow-hidden" style={{ minWidth: '12rem' }}>
                    <div className="bg-holder bg-card" style={{ backgroundImage: 'url(assets/img/illustrations/corner-3.png)' }}>
                    </div>
                    <div className="card-body position-relative">
                        <h6>Abonnements en cours<span className="badge badge-soft-success rounded-capsule ml-2"></span></h6>
                        <div className="display-4 fs-4 mb-2 font-weight-normal text-sans-serif" data-countup="{&quot;count&quot;:43594,&quot;format&quot;:&quot;comma&quot;,&quot;prefix&quot;:&quot;$&quot;}">{formatResult(countActiveSubscription)}</div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Dashbord;