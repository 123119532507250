import React, { useState } from 'react';
import { listTokens, sendPushNotification, findUserByEmail } from '../../services/api'

const SendNotif = ({ setProgress, setOpenToast }) => {


    const [formData, setFormData] = useState({
        title: '',
        description: '',
        img: '',
        link: '',
        target: 'me'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        let {
            title,
            description,
            img,
            link,
            target
        } = formData;

        if (!title || !description) {
            return;
        }

        try {

            setProgress(true);

            let message = {

                data: {
                    link: link
                },

                "webpush": {
                    "headers": {
                        "Urgency": "high"
                    },
                    "notification": {
                        "title": title,
                        "body": description,
                        "image": img,
                        "requireInteraction": "true",
                        "badge": "https://exams-tcfcanada-images.s3.us-east-2.amazonaws.com/logo144.png",
                        "icon": "https://exams-tcfcanada-images.s3.us-east-2.amazonaws.com/logo144.png"
                    }
                }
            }

            if (target === 'me') {
                //recherche de mes tokens
                const userRep = await findUserByEmail('mfi.tene@gmail.com');
                const userData = userRep.data?.data;

                if (!userData) {
                    setProgress(false);
                    setOpenToast({ open: true, msg: 'Echec, token non trouvé', severity: 'error' });
                    return;
                }

                const userID = userData._id;

                const tokensRep = await listTokens({ userID });

                const tokens = tokensRep.data.data;
                const promises = []

                tokens.forEach(token => {
                    const tmp = { ...message, ...{ token: token.token } };
                    promises.push(sendPushNotification(tmp));
                });

                await Promise.all(promises);

                setProgress(false);
                setOpenToast({ open: true, msg: "Notifications envoyées", severity: 'success' });

            }

            else {
                await sendPushNotification(message);
                setProgress(false);
                setOpenToast({ open: true, msg: "Notifications envoyées", severity: 'success' });
            }

        } catch (error) {
            console.log(error);
            setProgress(false);
            setOpenToast({ open: true, msg: "Une erreur s'est produite", severity: 'error' });
        }

    };



    return (
        <div className='d-flex justify-content-center my-7'>
            <div className="container col-12 col-sm-12 col-md-8 col-xl-6">
                <h3>ENVOI DES NOTIFICATIONS</h3>
                {JSON.stringify(formData)}
                <form onSubmit={handleSubmit}>

                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">
                            Titre
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">
                            Description
                        </label>
                        <textarea
                            className="form-control"
                            id="description"
                            name="description"
                            rows={4}
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                            Url de l'image
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="img"
                            name="img"
                            value={formData.img}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                            Lien de redirection
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="link"
                            name="link"
                            value={formData.link}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="destinataire" className="form-label">
                            Destinataire(s)
                        </label>
                        <select
                            className="form-select"
                            id="target"
                            name="target"
                            value={formData.target}
                            onChange={handleChange}
                        >
                            <option value="me">Moi</option>
                            <option value="all">Tout le monde</option>
                        </select>
                    </div>

                    <button type="submit" className="btn btn-primary">
                        Envoyer
                    </button>
                </form>
            </div>
        </div>
    );
};

export default SendNotif;