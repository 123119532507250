import React from 'react';

const QuestionImage = ({ exists, src, srcSet }) => {

    const [rendu, setRendu] = React.useState(null);

    React.useEffect(() => {
        setRendu(
            <a href={src}>
                <img decoding="async" loading="lazy" className="aligncenter size-full wp-image-3575 img_quest" srcSet={src} sizes="(max-width: 719px) 100vw, 719px" />
            </a>
        )
    }, [src])

    return (
        <>
            {
                (exists === true) ? (
                    <h2>
                        {rendu}
                    </h2>
                ) : null
            }
        </>
    );
};

export default QuestionImage;