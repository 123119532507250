import * as React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Avatar from '@mui/material/Avatar';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import PermCameraMicIcon from '@mui/icons-material/PermCameraMic';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { onAuthStateChanged } from "firebase/auth";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Divider from '@mui/material/Divider';
import { signOut } from "firebase/auth";
import { NavLink, Navigate, useNavigate } from 'react-router-dom';
import MyToast from './MyToast';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MySpinner from './MySpinner';
import { auth } from "../firebase";
import CalculateIcon from '@mui/icons-material/Calculate';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';



const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
}));



function Menu() {

    const navigate = useNavigate();

    const [user, setUser] = React.useState(null);
    const [inProgress, setinProgress] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });

    React.useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                setUser(authUser)
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleLogout = () => {
        setinProgress(true);
        signOut(auth).then(() => {
            setinProgress(false);
            setOpenToast({
                open: true,
                msg: 'Déconnexion réussie',
                severity: 'success'
            })
            navigate("/accueil");
        }).catch((error) => {
            setinProgress(false);
            setOpenToast({
                open: true,
                msg: 'Une erreur s\'est produite. Veuillez réessayer ultérieurement svp',
                severity: 'error'
            })
        });
    }


    return (
        <>



            <Navbar collapseOnSelect expand="md" className="bg-body-tertiary" fixed="top" bg="dark" data-bs-theme="dark" id="myNav">

                <div className='mb-6' style={{ width: '100%', height: 'auto', background: '#005800', position: 'fixed', top: 0, zIndex: 1000, padding: '15px', textAlign: 'center', color: 'white' }}>
                    <p style={{ fontSize: '0.6em', lineHeight: '1.2' }}>
                        <NavLink to={'/accompagnement-immigration'} style={{ color: 'white', display: 'block', marginTop: '1px' }}>
                            Cliquez ici pour découvrir notre service d'accompagnement pour votre projet d'immigration au Canada.
                        </NavLink>

                    </p>
                </div>

                <Container className="mb-4 pt-7">
                    <Navbar.Brand href="#home">
                        <div className='d-sm-none d-none d-md-block'>
                            <NavLink to={'/accueil'}>
                                <img className="" src='../assets/img/logo.png' alt='EXAMS-TCFCANADA' style={{ width: '55%', height: '35%' }} />
                            </NavLink>
                        </div>
                        <div className='d-md-none ml-2'>
                            <NavLink to={'/accueil'}>
                                <img className="" src='../assets/img/logo.png' alt='EXAMS-TCFCANADA' style={{ width: '40%', height: '25%' }} />
                            </NavLink>
                        </div>
                    </Navbar.Brand>

                    {
                        user ? (
                            <StyledBadge
                                className="d-block d-sm-block d-md-none"
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >
                                <Avatar
                                    sx={{ width: 24, height: 24 }}
                                    src={<AccountCircleIcon />}
                                />
                            </StyledBadge>
                        ) : null
                    }

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto mt-md-none mt-2 ml-2 ml-md-0">
                            <hr />
                            <Nav.Link className='text-white' onClick={e => navigate('/sujets-comprehension-orale')} >
                                <HeadphonesIcon className='text-info mr-1' style={{ fontSize: '1.2rem' }} />
                                COMPRÉHENSION ORALE</Nav.Link>
                            <Nav.Link className='text-white' onClick={e => navigate('/sujets-comprehension-ecrite')}>
                                <MenuBookOutlinedIcon className='text-danger mr-1' style={{ fontSize: '1.2rem' }} />
                                COMPRÉHENSION ÉCRITE</Nav.Link>
                            <Nav.Link className='text-white' onClick={e => navigate('/sujets-expression-orale')} >
                                <PermCameraMicIcon className='text-success mr-1' style={{ fontSize: '1.2rem' }} />
                                EXPRESSION ORALE</Nav.Link>
                            <Nav.Link className='text-white' onClick={e => navigate('/sujets-expression-ecrite')} >
                                <KeyboardIcon className='text-warning mr-1' style={{ fontSize: '1.2rem' }} />
                                EXPRESSION ÉCRITE</Nav.Link>

                            <Nav.Link className='text-white d-inline-block d-sm-inline-block d-md-none' onClick={e => navigate('/calculatrice-nclc')} >
                                <CalculateIcon className='text-secondary mr-1' style={{ fontSize: '1.2rem' }} />
                                CALCULATRICE NCLC</Nav.Link>

                            <Nav.Link className='text-white d-inline-block d-sm-inline-block d-md-none' onClick={e => navigate('/offres')} >
                                <CalculateIcon className='text-secondary mr-1' style={{ fontSize: '1.2rem' }} />
                                NOS OFFRES</Nav.Link>

                            {
                                (user) ? (
                                    <>
                                        <Divider variant="middle" className="mt-3 mb-2" />
                                        <NavLink className='text-white d-md-none d-inline-block d-sm-inline-block my-2' to={'/abonnement-historique'}>MES ABONNEMENTS</NavLink>
                                        {
                                            (user?.email === 'mfi.tene@gmail.com' || user?.email === 'tefuncowm@gmail.com') ? (
                                                <NavLink className='text-white d-md-none d-inline-block d-sm-inline-block my-2' to={'/administration?service=1'} >ADMINISTRATION</NavLink>
                                            ) : null
                                        }
                                        <NavLink className='text-white d-md-none d-inline-block d-sm-inline-block my-2' to={'/modifier-mot-passe'} >CHANGER DE MOT DE PASSE</NavLink>
                                        <NavLink className='text-white d-md-none d-inline-block d-sm-inline-block my-2' onClick={handleLogout}>DECONNEXION</NavLink>
                                    </>

                                ) : (
                                    <>
                                        <Divider variant="middle" className="mt-3 mb-2" />
                                        <NavLink className=' d-md-none d-inline-block d-sm-inline-block my-2' to={'/connexion/email'}>CONNEXION</NavLink>
                                        <NavLink className=' d-md-none d-inline-block d-sm-inline-block my-2' to={'/inscription/email'}>INSCRIPTION</NavLink>
                                    </>
                                )
                            }

                        </Nav>
                        <Nav className='d-none d-sm-none d-md-flex' >


                            {
                                (user) ? (
                                    <>

                                        <Avatar src={<AccountCircleIcon />} />
                                        <NavDropdown title={<span className='text-white'>Marius</span>} id="collasible-nav-dropdown" className='text-white'>
                                            <NavDropdown.Item href="#" onClick={e => navigate('/abonnement-historique')}>Mes abonnements</NavDropdown.Item>
                                            {
                                                (user?.email === 'mfi.tene@gmail.com' || user?.email === 'tefuncowm@gmail.com') ? (
                                                    <NavDropdown.Item href="#" onClick={e => navigate('/administration?service=1')}>Administration</NavDropdown.Item>
                                                ) : null
                                            }
                                            <NavDropdown.Item href="#" onClick={e => navigate('/modifier-mot-passe')}>Changer de mot de passe</NavDropdown.Item>
                                            <NavDropdown.Item href="#" onClick={handleLogout}>Déconnexion</NavDropdown.Item>
                                        </NavDropdown>
                                    </>
                                ) : (
                                    <>
                                        <NavLink to={'/connexion/email'}>CONNEXION</NavLink>
                                        <NavLink className='ml-md-3 ml-0 ml-sm-0' to={'/inscription/email'}>INSCRIPTION</NavLink>
                                    </>
                                )
                            }

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />

        </>

    );
}

export default Menu;