import React from 'react';
import Footer from '../components/Footer';
import Menu from '../components/Navbar';
import { NavLink } from 'react-router-dom';

const P_NonAuthorize = () => {
    return (
        <>
            <Menu />
            <div style={{ marginTop: '12.5rem' }}></div>
            <div className="row mx-0 px-0">
                <div className="col-sm-1 col-md-2 col-lg-3 col-xxl-4"></div>
                <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-4">
                    <div className="card">
                        <div className="card-body p-4 p-sm-5">
                            <div className="display-1 text-300 fs-error d-flex justify-content-center">401</div>
                            <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold d-flex justify-content-center"><h5>Vous ne disposez pas d'autorisation pour accéder à cette page</h5></p>
                            <hr />
                            <p>Rassurez vous que votre abonnement actuel vous donne accès à cette ressource. Si vous pensez qu'il s'agit d'une erreur <NavLink to={'/contactez-nous'}>Contactez-nous. </NavLink></p>.
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginBottom: '5rem' }}></div>
            <Footer />
        </>
    );
};

export default P_NonAuthorize;