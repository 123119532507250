import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Typography from '@mui/material/Typography';
import Container from 'react-bootstrap/Container';

const Timer = ({ duration, timeMode, setCurrentTime}) => {

    const [timeRemaining, setTimeRemaining] = React.useState(duration*60);
    const [progress, setProgress] = React.useState(60*duration);


    React.useEffect(() => {
        const interval = setInterval(() => {
            if(timeRemaining > 0) {
                const tr = timeRemaining - 1;
                setTimeRemaining(tr);
                setProgress(tr);
                setCurrentTime(tr);
            }
        }, 1000)

        return () => {
            clearInterval(interval);
        }
    }, [timeRemaining]);

    const minutes = Math.floor(timeRemaining/60);
    const seconds = timeRemaining % 60;

    return (
        <>
            {
                timeMode === 'exams' ? (
                    <nav className="navbar fixed-bottom navbar-light bg-light">
                        <Container>

                            <Typography variant="h6" gutterBottom>
                                Temps restant: {minutes} min {seconds} sec
                            </Typography> 

                            <ProgressBar className='w-100' animated variant="info" max={60*duration} now={progress} style={{ marginTop: '3px' }} />

                        </Container>
                    </nav>
                ) : null
            }
        </>
    );
};

export default Timer;