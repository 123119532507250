import React, { useState } from 'react';
import { createOffer, listConstantes } from '../../services/api'


const CreateOffer = ({ setProgress, setOpenToast }) => {


    const [formData, setFormData] = useState({
        name: '',
        description: '',
        duration: '',
        price: '',
        ce: 0,
        co: 0,
        ee: 0,
        eo: 0,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = {
            name: formData.name,
            description: formData.description,
            duration: formData.duration,
            price: formData.price
        }

        const subjectsTypes = [];
        const qce = getQuantity('ce');
        const qco = getQuantity('co');
        const qee = getQuantity('ee');
        const qeo = getQuantity('eo');

        if (qce) {
            subjectsTypes.push(qce);
        }

        if (qco) {
            subjectsTypes.push(qco);
        }

        if (qee) {
            subjectsTypes.push(qee);
        }

        if (qeo) {
            subjectsTypes.push(qeo);
        }

        data.subjectsTypes = subjectsTypes;

        setProgress(true);

        createOffer(data).then((resp) => {
            setOpenToast({
                open: true,
                msg: 'Offre créée avec succès',
                severity: 'success'
            });

            setProgress(false);
        }).catch(error => {
            setOpenToast({
                open: true,
                msg: 'Erreur lors de l\'enregistrement de l\'offre',
                severity: 'error'
            });

            setProgress(false);
            return;
        })

    };


    const getQuantity = stype => {
        if (formData[stype.toLowerCase()] && formData[stype.toLowerCase()] !== undefined && formData[stype.toLowerCase()] !== '') {
            if (parseInt(formData[stype.toLowerCase()]) > 0) {
                return {
                    id: listConstantes()[stype.toUpperCase()].id,
                    quantity: parseInt(formData[stype.toLowerCase()])
                }
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    }

    return (
        <div className='d-flex justify-content-center my-7'>

            <div className="container col-12 col-sm-12 col-md-8 col-xl-6">
                <h3>CREATION DES OFFRES</h3>

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                            Nom
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">
                            Description
                        </label>
                        <textarea
                            className="form-control"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="duration" className="form-label">
                            Durée
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="duration"
                            name="duration"
                            value={formData.duration}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="price" className="form-label">
                            Prix
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="price"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="ce" className="form-label">
                            CE
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="ce"
                            name="ce"
                            value={formData.ce}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="co" className="form-label">
                            CO
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="co"
                            name="co"
                            value={formData.co}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="ee" className="form-label">
                            EE
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="ee"
                            name="ee"
                            value={formData.ee}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="eo" className="form-label">
                            EO
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="eo"
                            name="eo"
                            value={formData.eo}
                            onChange={handleChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Soumettre
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CreateOffer;
