import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';


function EditQuestionModal({ isOpen, onClose, onSave, progress, question }) {

    const [editedQuestion, setEditedQuestion] = useState({ ...question });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setEditedQuestion({
            ...editedQuestion,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(editedQuestion);
    };

    return (

        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Éditer la question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='w-100' onSubmit={handleSubmit}>
                    <div className='form-group col-12'>
                        <label>Consigne de la question:</label>
                        <div className='form-row'>
                            <textarea
                                name="consigne"
                                className='col-12'
                                rows="4"
                                value={editedQuestion.consigne}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='form-group col-12'>
                        <label>Réponse de la question:</label>
                        <div className='form-row'>
                            <textarea
                                name="reponse"
                                className='col-12'
                                rows="4"
                                value={editedQuestion.reponse}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='form-group col-12'>
                        <label>Question gratuite ?</label>
                        <div className='form-row'>
                            <select name="isFree" className='col-12' value={editedQuestion.isFree} onChange={handleChange}>
                                <option value={true}>Oui</option>
                                <option value={false}>Non</option>
                            </select>
                        </div>
                    </div>
                    <div className='form-group'>
                        <Button variant="primary" type='submit' disabled={progress} className='ml-2'>
                            {progress === true ? (
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : null
                            }
                            Enregistrer
                        </Button>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Annuler
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default EditQuestionModal;
