import * as React from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import { auth } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { findUserByEmail, saveUser, sendMailWithMailJet, saveSubscription } from "../services/api";
import MyToast from './MyToast';
import MySpinner from './MySpinner';


export default function SignUp() {

    const navigate = useNavigate();

    const [registerData, setRegisterData] = React.useState({country: 'Cameroon'});
    const [inProgress, setinProgress] = React.useState(false);
    /*  const [countries, setCountries] = React.useState(listCountries()); */
    const [countries, setCountries] = React.useState([]);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });


    React.useEffect(() => {
        // Fonction pour charger la liste des pays depuis l'API REST Countries
        const fetchCountries = async () => {
            try {
                const response = await fetch('https://restcountries.com/v3.1/all');
                const data = await response.json();

                // Triez les pays par nom
                data.sort((a, b) => a.name.common.localeCompare(b.name.common));

                setCountries(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données depuis l\'API :', error);
            }
        };

        // Appelez la fonction pour charger la liste des pays lors du montage du composant
        fetchCountries();
    }, []);



    const handleChange = e => {
        setRegisterData({ ...registerData, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        //vérifions les paramètres requis
        let { name, firstName, email, country, password, cfpassword } = registerData;
        if (!name || !email || !country || !password || !cfpassword) {
            setOpenToast({
                open: true,
                msg: "Veuillez renseigner tous les champs !",
                severity: "error"
            })
            return;
        }

        if (password.length < 6) {
            setOpenToast({
                open: true,
                msg: "Le mot de passe doit voir au moins 6 caractères",
                severity: "error"
            })
            return;
        }

        if (password !== cfpassword) {
            setOpenToast({
                open: true,
                msg: "Les mots de passe ne correspondent pas",
                severity: "error"
            })
            return;
        }


        try {

            setinProgress(true);

            const exists = await findUserByEmail(email);
            if (!exists || exists.status !== 200) {
                setOpenToast({
                    open: true,
                    msg: "Une erreur s'est produite, veuillez réessayer ultérieurement",
                    severity: "error"
                })
                setinProgress(false);
                return;
            }

            if (exists && exists.data?.data) {
                setOpenToast({
                    open: true,
                    msg: "Cette adresse email est déjà utilisée",
                    severity: "error"
                })
                setinProgress(false);
                return;
            }


            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const userData = {
                userID: user.uid,
                name,
                email,
                country,
                firstName
            }

            const rep = await saveUser(userData);

            setOpenToast({
                open: true,
                msg: "Inscription réussie",
                severity: "success"
            })


            setinProgress(false);

            navigate('/accueil');

            /* const subscription = await saveSubscription({
                offerID: '6514e39fa3d34a00087a10bb',
                userID: user.uid,
                startDate: new Date().toISOString(),
                endDate: new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000).toISOString(),
                couponID: '6526bb7a6df040c1814deb33',
                subscriptionStatus: 'ACTIVED'
            }); */

            sendMailWithMailJet({
                from: 'services@exams-tcfcanada.com',
                to_email: email,
                from_name: 'EXAMS-TCF-CANADA',
                subject: `Confirmation d'inscription à EXAMS-TCFCANADA`,
                text: `
Cher(e) ${name},\n
Nous sommes ravis de vous accueillir sur notre plateforme ! Vous avez réussi avec succès à vous inscrire, et nous sommes très heureux de vous compter parmi nos utilisateurs.
Nous tenons à vous remercier de nous avoir choisi pour vous préparer à l'exament du TCF CANADA. 
Votre compte a été créé avec succès, et nous sommes ravis de vous annoncer que vous bénéficiez d'un essai gratuit de 5 jours à tous les sujets et tests disponibles sur exams-tcfcanada.com. 
Nous espérons que cette période d'essai vous permettra de mieux comprendre notre plateforme et de tirer pleinement parti de tout ce que nous avons à offrir.\n
Si vous avez des questions, des préoccupations ou si vous avez besoin d'une assistance quelconque, n'hésitez pas à nous contacter à l'adresse examstcfcanada@gmail.com ou via https://www.exams-tcfcanada.com/contactez-nous.
Cordialement,\n
Service client
EXAMS-TCFCANADA.COM
https://exams-tcfcanada.com
services@exams-tcfcanada.com
examstcfcanada@gmail.com

                `
            });

            sendMailWithMailJet({
                from: 'services@exams-tcfcanada.com',
                to_email: `examstcfcanada@gmail.com`,
                from_name: 'Inscription',
                subject: 'Nouvelle inscription',
                text: `Nouvelle inscription avec l'adresse email suivante: \n\n ${email}`
            });

        } catch (error) {
            setinProgress(false);
            const errorCode = error?.code;
            const errorMessage = error?.message;

            if (errorCode == 'auth/email-already-in-use') {
                setOpenToast({
                    open: true,
                    msg: "Cette adresse email est déjà utilisée",
                    severity: "error"
                })
                return;
            }

            else if (errorCode === 'auth/network-request-failed') {
                setOpenToast({
                    open: true,
                    msg: "Vérifiez votre connexion internet",
                    severity: "error"
                })
            }

            else {
                setOpenToast({
                    open: true,
                    msg: "Une erreur s'est produite, veuillez réessayer ultérieurement",
                    severity: "error"
                })
            }
        }

    };

    return (
        <div className="row mx-0 px-0">
            <div className="col-sm-1 col-md-1 col-lg-2 col-xxl-3"></div>
            <div className="col-sm-10 col-md-10 col-lg-8 col-xxl-6">

                <div className="card login-form">
                    <div className="card-body p-4 p-sm-5">
                        <div className="row text-left justify-content-between align-items-center mb-2">
                            <div className="col-auto">
                                <h2> Inscription</h2>
                            </div>
                            <div className="col-auto mt-2">
                                <p className="fs--1 text-600 mb-2">Vous avez déjà un compte ?
                                    <NavLink className='ml-1' to="/connexion/email" >
                                        Connexion
                                    </NavLink>
                                </p>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} className="customForm mt-3 form-input-material">

                            <div className="form-field animation a3">
                                <label>Votre nom</label>
                                <input type="text"
                                    name='name'
                                    id="name"
                                    onChange={handleChange}
                                    required />
                            </div>

                            <div className="form-field animation a3 mt-2">
                                <label>Votre E-mail</label>
                                <input type="email"
                                    name='email'
                                    id="email"
                                    onChange={handleChange}
                                    required />

                            </div>

                            <div className="form-field animation a3 mt-2">
                                <label>Choisir votre Pays</label>
                                <select className="custom-select" value={registerData.country} name="country" id="country" onChange={handleChange} style={{ height: '50px' }} >
                                    {
                                        countries.map((country) => (
                                            <option key={country.cca2} value={country.name.common}>
                                                {country.name.common}
                                            </option>

                                        ))
                                    }
                                </select>
                            </div>

                            <div className="form-row mt-2">
                                <div className="mb-3 col-12 col-sm-12 col-md-6">
                                    <label>Mot de passe</label>
                                    <div className="">
                                        <input type="password"
                                            name='password'
                                            id="password"
                                            onChange={handleChange}
                                            required />

                                    </div>

                                </div>

                                <div className="mb-3 col-12 col-sm-12 col-md-6">
                                    <div className="">
                                        <label>Confimer le mot de passe</label>
                                        <input type="password"
                                            name='cfpassword'
                                            id="cfpassword"
                                            onChange={handleChange}
                                            required />

                                    </div>

                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <button className="btn btn-block mt-3" type="submit" name="submit" style={{ backgroundColor: '#2b3035', color: 'white' }}>M'inscrire</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />

        </div>

    );
}