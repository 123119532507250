import React from 'react';
import { deleteOffer, listOffers } from '../../services/api';

const DeleteOffer = ({ setProgress, setOpenToast }) => {

    const [offers, setOffers] = React.useState([]);
    const [selectedOffer, setSelectedOffer] = React.useState('');
    const [sup_type, setSup_type] = React.useState('false');

    React.useEffect(() => {
        setProgress(true);

        listOffers({}).then(resp => {
            setOffers(resp.data.data || []);
            setProgress(false);
        })
            .catch(err => {
                setProgress(false);
                setOpenToast({
                    open: true,
                    msg: 'Erreur lors du chargement de des offres',
                    severity: 'error'
                })
            })
    }, [])


    // Fonction de gestionnaire de changement pour l'élément select
    const handleSelectChange = (event) => {
        setSelectedOffer(event.target.value);
    };

    const handleSuppTypeChange = (event) => {
        setSup_type(event.target.value);
    };



    // Fonction de gestionnaire de soumission du formulaire
    const handleSubmit = (event) => {
        event.preventDefault();

        if (!selectedOffer || selectedOffer === '') return;

        const data = {
            offerID: selectedOffer,
            completed: sup_type
        }

        console.log(data);

        setProgress(true);
        deleteOffer(data).then(resp => {
            setProgress(false);
            setOpenToast({
                open: true,
                msg: 'Offre supprimée avec succès',
                severity: 'success'
            })
        })
        .catch(err => {
            console.log(err);
            setProgress(false);
            setOpenToast({
                open: true,
                msg: 'Erreur lors de la suppression de l\'offre',
                severity: 'error'
            })
        })

    };


    return (
        <div className='d-flex justify-content-center my-6'>

            <div className="container col-12 col-sm-12 col-md-8 col-xl-6">
                <h3>SUPPRESSION DES OFFRES</h3>

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="offreSelect">Sélectionnez une offre :</label>

                        <select
                            id="offreSelect"
                            className="form-control"
                            value={selectedOffer}
                            onChange={handleSelectChange}
                        >
                            <option value="">Sélectionnez une offre</option>
                            {
                                offers.map((offer) => (

                                    <option value={offer._id}>{offer.name}</option>
                                ))
                            }

                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="offreSelect">Type de suppression :</label>

                        <select
                            id="supp_type"
                            className="form-control"
                            value={sup_type}
                            onChange={handleSuppTypeChange}
                        >
                            <option value="false">Partielle</option>
                            <option value="true">Complète</option>
                           
                        </select>
                    </div>

                    <button type="submit" className="btn btn-primary">
                        Soumettre
                    </button>
                </form>
            </div>
        </div>
    );
};

export default DeleteOffer;