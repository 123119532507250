import React, { useState } from 'react';
import {
    ref,
    uploadBytesResumable,
    getDownloadURL,
    getStorage
} from 'firebase/storage';

import { addExpressionSubject, getSubject, listConstantes, listSessionsSubjects, verifyUserAccessToSubject } from '../../services/api';
import { uploadFileToStorage } from '../../services/util';



const AddExpressionSubject = ({ setProgress, setOpenToast }) => {

    const [sessions, setSessions] = useState([]);
    const [session_, setSession_] = useState('');
    const [newSessionName, setNewSessionName] = useState('');
    const [subjectType, setSubjectType] = useState(listConstantes().EO.id);
    const [subject, setSubject] = useState('');
    const [taskType, setTaskType] = useState('1');
    const [free, setFree] = useState('true');
    const [subjectImage, setSubjectImage] = useState(null);
    const [correction, setCorrection] = useState('');

    React.useEffect(() => {
        setProgress(true);
        listSessionsSubjects({ subjectTypeID: subjectType })
            .then(resp => {
                setSessions(resp.data.data?.sessions || []);
                setProgress(false);
            })
            .catch(err => {
                setProgress(false);
            })


    }, [subjectType])



    const handleSubmit = (e) => {
        e.preventDefault();



        setProgress(true);

        uploadFileToStorage(ref, uploadBytesResumable, getDownloadURL, getStorage(), subjectImage, `tcf-canada-formation/expressions/images-prod`, (err, url) => {
            if(err) {
                setOpenToast({
                    open: true,
                    msg: 'Erreur lors de l\'enregistrement de l\'image',
                    severity: 'error'
                });

                setProgress(false);
                return;
            }
            
            //enregistrement de l'image
            const data = { 
                session: (session_ === "" || !session_)? newSessionName: session_,
                subjectTypeID: subjectType, 
                ennonce: subject, 
                taskType, 
                free: free,
                subjectImage: url, 
                correction 
            };

            addExpressionSubject(data)
                .then(resp => {
                    setSession_('')
                    setCorrection('');
                    setSubjectType(listConstantes().EO.id);
                    setTaskType('1');
                    setSubjectImage(null);
                    setNewSessionName('');
                    setSubject('');
                    setOpenToast({
                        open: true,
                        msg: 'Sujet enregistré avec succès',
                        severity: 'success'
                    });

                    setProgress(false);

                })
                .catch(err => {

                    console.log(err);
                    setOpenToast({
                        open: true,
                        msg: 'Une erreur s\'est produite',
                        severity: 'error'
                    });
                    setProgress(false);
                })


        })

    };


    return (
        <div className='d-flex justify-content-center my-6'>

            <div className="container col-12 col-sm-12 col-md-8 col-xl-6">
                <h3>ENREGISTREMENT DES SUJETS D'EXPRESSION</h3>

                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Type de sujet</label>
                        <select
                            className="form-control"
                            value={subjectType}
                            onChange={(e) => setSubjectType(e.target.value)}
                        >
                            <option value={listConstantes().EO.id}>Expression orale</option>
                            <option value={listConstantes().EE.id}>Expression écrite</option>


                        </select>
                    </div>

                    <div className="form-group">
                        <label>Session</label>
                        <select
                            className="form-control"
                            value={session_}
                            onChange={(e) => setSession_(e.target.value)}
                        >

                            <option value="">Choisir une sesion</option>
                            {
                                sessions.map((sess) => (
                                    <option value={sess.session}>{sess.session}</option>
                                ))
                            }


                        </select>
                    </div>

                    <div className="form-group">
                        <label>Nouvelle Session</label>
                        <input
                            type="text"
                            className="form-control"
                            value={newSessionName}
                            onChange={(e) => setNewSessionName(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Type de Sujet</label>
                        <select
                            className="form-control"
                            required
                            value={taskType}
                            onChange={(e) => setTaskType(e.target.value)}
                        >
                            <option className='text-uppercase' value={'1'} >Tâche 1</option>
                            <option className='text-uppercase' value={'2'}>Tâche 2</option>
                            <option className='text-uppercase' value={'3'}>Tâche 3</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Sujet</label>
                        <textarea
                            className="form-control"
                            required
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Image du Sujet</label>
                        <input
                            type="file"
                            className="form-control-file"
                            onChange={(e) => setSubjectImage(e.target.files[0])}
                        />
                    </div>

                    <div className="form-group">
                        <label>Correction</label>
                        <textarea
                            className="form-control"
                            value={correction}
                            onChange={(e) => setCorrection(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Gratuit</label>
                        <select
                            className="form-control"
                            required
                            value={free}
                            onChange={(e) => setFree(e.target.value)}
                        >
                            <option className='text-uppercase' value={'true'} >Oui</option>
                            <option className='text-uppercase' value={'false'} >Non</option>
                        </select>
                    </div>

                    <button type="submit" className="btn btn-primary">
                        Soumettre
                    </button>
                </form>
            </div>

        </div>
    );
};

export default AddExpressionSubject;