import React from 'react';
import Button from 'react-bootstrap/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';

const SubjectBtn = ( {lock, label, path, withArrow}) => {

    const navigate = useNavigate();

    const handleClick = e => {
        e.preventDefault();
        navigate(path)
    }


    return (
        <>
            <Button onClick={handleClick} variant={`${lock === true? 'outline-secondary': 'danger'}`} disabled={lock}
                style={{fontWeight: 'bolder', width: '100%'}}
            >
                <span className='mr-1'>{label}</span>
                {
                    lock == true ? (
                        <LockOutlinedIcon />
                    ): null
                }

                {
                    withArrow === true ? (
                        <KeyboardArrowRightIcon />
                    ): null
                }
            </Button>
        </>
    );
};

export default SubjectBtn;