import React from 'react';

const SubjectTitleSecond = ({subTitle, title, description, img}) => {

    return (
        <div className="quest_title_container">
            <img className='d-md-none d-xs-block d-sm-block d-block' src={img} alt={`${title} ${subTitle}`} style={{ width: '100%' }} />
            <img className='d-md-block d-xs-none d-none d-sm-none d-sm-none' src={img} alt={`${title} ${subTitle}`} style={{ width: '100%', height: '18rem' }} />

            <div className="quest_title_text col-12">
                <div className='row'>
                    <div className='col-12 mt-4'>
                        <h3 className='font-weight-extra-bold'>{title} <span style={{color: '#19879e'}}>{subTitle}</span></h3>
                    </div>

                    <div className='col-12 mt-2'>
                        <h6 style={{color: '#E5E7E5', fontSize: '0.8rem'}}>{description}</h6>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default SubjectTitleSecond;

