import './App.css';
import * as React from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import P_Registration from './pages/P_Registration';
import P_Home from './pages/P_Home';
import P_Login from './pages/P_Login';
import P_PasswordRecover from './pages/P_PasswordRecover';
import P_ResetPassword from './pages/P_ResetPassword';
import P_Subject_C from './pages/P_Subject_C';
import P_AllSubjects from './pages/P_AllSubjects';
import P_Privacy from './pages/P_Privacy';
import P_Error from './pages/P_Error';
import A_ComprehensionOrale from './pages/A_ComprehensionOrale';
import A_ComprehensionEcrite from './pages/A_ComprehensionEcrite';
import A_ExpressionOrale from './pages/A_ExpressionOrale';
import A_ExpressionEcrite from './pages/A_ExpressionEcrite';
import P_NotFound from './pages/P_NotFound';
import P_ContactsUs from './pages/P_ContactsUs';
import P_About from './pages/P_About';
import P_CO_Subject from './pages/P_CO_Subject';
import ProtectedRoute from './components/ProtectedRoute';
import P_Subscription from './pages/P_Subscription';
import P_NonAuthorize from './pages/P_NonAuthorize';
import FirebaseAuthContext from './Contexts/AuthContext';
import P_SubscriptionHistory from './pages/P_SubscriptionHistory';
import P_ChangePassword from './pages/P_ChangePassword';
import P_EO_Subject from './pages/P_EO_Subject';
import P_Subject_E from './pages/P_Subject_E';
import P_CE_Subject from './pages/P_CE_Subject';
import P_EE_Subject from './pages/P_EE_Subject';
import P_Admin from './pages/P_Admin';
import P_CalculatriceNcLc from './pages/P_CalculatriceNcLc';
import Notification from './components/Notification';
import P_Offers from './pages/P_Offers';
import P_AccompagnementImmigration from './pages/P_AccompagnementImmigration';
/* import ReactGA from 'react-ga'; */

/* ReactGA.initialize('G-0CJKQ28N22'); */



function ScrollToTop() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return null;
}



function App() {



  const handleContextMenu = (event) => {
    event.preventDefault();
  }

  React.useEffect(() => {
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    }
  }, [])


  return (
    <>
      <FirebaseAuthContext>

        <BrowserRouter>
          <ScrollToTop />
          <Notification />
          <Routes>
            <Route path='/' element={<P_Home />} />
            <Route path='/accueil' element={<P_Home />} />
            <Route path='/connexion/:method' element={<P_Login />} />
            <Route path='/inscription/:method' element={<P_Registration />} />
            <Route path='/mot-de-passe-oublie' element={<P_PasswordRecover />} />
            <Route path='/reinitialiser-mot-passe' element={<P_ResetPassword />} />
            <Route path='/sujet-comprehension' element={<P_Subject_C />} />
            <Route path='/sujet-expression' element={<P_Subject_E />} />
            <Route path='/sujets' element={<P_AllSubjects />} />
            <Route path='/sujets-comprehension-orale' element={<P_CO_Subject />} />
            <Route path='/sujets-expression-orale' element={<P_EO_Subject />} />
            <Route path='/sujets-comprehension-ecrite' element={<P_CE_Subject />} />
            <Route path='/sujets-expression-ecrite' element={<P_EE_Subject />} />
            <Route path='/contactez-nous' element={<P_ContactsUs />} />
            <Route path='/article-comprehension-orale' element={<A_ComprehensionOrale />} />
            <Route path='/article-comprehension-ecrite' element={<A_ComprehensionEcrite />} />
            <Route path='/article-expression-orale' element={<A_ExpressionOrale />} />
            <Route path='/article-expression-ecrite' element={<A_ExpressionEcrite />} />
            <Route path='/a-propos' element={<P_About />} />
            <Route path='/calculatrice-nclc' element={<P_CalculatriceNcLc />} />
            <Route path='/politique-confidentialite' element={<P_Privacy />} />
            <Route path='/error' element={<P_Error />} />
            <Route path='/not-found' element={<P_NotFound />} />
            <Route path='/non-autorise' element={<P_NonAuthorize />} />
            <Route path='/offres' element={<P_Offers />} />
            <Route exact="true" path='/error' element={<P_Error />} />
            <Route path='/accompagnement-immigration' element={<P_AccompagnementImmigration />} />


            {/* Routes protégées */}
            <Route exact path='/error' element={<ProtectedRoute />}>
              <Route exact="true" path='/error' element={<P_Error />} />
            </Route>

            

            <Route exact path='/abonnement-historique' element={<ProtectedRoute />}>
              <Route exact="true" path='/abonnement-historique' element={<P_SubscriptionHistory />} />
            </Route>

            <Route exact path='/abonnement' element={<ProtectedRoute />}>
              <Route exact="true" path='/abonnement' element={<P_Subscription />} />
            </Route>


            <Route exact path='/administration' element={<ProtectedRoute />}>
              <Route exact="true" path='/administration' element={<P_Admin />} />
            </Route>

            <Route exact path='/modifier-mot-passe' element={<ProtectedRoute />}>
              <Route exact="true" path='/modifier-mot-passe' element={<P_ChangePassword />} />
            </Route>


            <Route path="*" element={<P_NotFound />} />

          </Routes>
        </BrowserRouter>
      </FirebaseAuthContext>
      {/* <Notification /> */}
    </>
  );
}

export default App;
