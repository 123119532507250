import React from 'react';
import Menu from '../components/Navbar';
import SubjectTitleSecond from '../components/SubjectTitleSecond';
import Divider from '@mui/material/Divider';
import Footer from '../components/Footer';
import AllSubjects from '../components/AllSubjects';

const P_AllSubjects = () => {
    
    return (
        <>
            <Menu />
            <div style={{ marginTop: '9.5rem' }}></div>
            <SubjectTitleSecond />
            <div style={{ marginTop: '2rem' }}></div>
            <span className='customDivider'>
                <Divider>LISTE DES EPREUVES AU TCF CANADA</Divider>
            </span>
            <div style={{ marginTop: '2rem' }}></div>
            <AllSubjects />
            <div style={{ marginTop: '2rem' }}></div>
            <Footer />
        </>
    );
};

export default P_AllSubjects;