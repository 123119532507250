import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const QuestionModal = ({show, setShow, title, question, setModalValue}) => {

    const handleAccept = () => {
        setModalValue('accept');
        setShow(false);
    };

    const handleCancel = () => {
        setModalValue('annuler');
        setShow(false);
    }

    const handleClose = () => setShow(false);


    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{question}</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleAccept}>
                    Continuer
                </Button>
                <Button variant="secondary" onClick={handleCancel}>
                    Annuler
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default QuestionModal;