import React from 'react';

const SubjectTitle = ({title, img}) => {
    return (
        <div className="quest_title_container">
            <img className='d-md-none d-xs-block d-sm-block d-block' src={img} alt={title} style={{ width: '100%'}} />
            <img className='d-md-block d-xs-none d-none d-sm-none d-sm-none' src={img} alt={title} style={{ width: '100%', height: '17rem'}} />
            
            <div className="quest_title_text">
                <h2 className='font-weight-extra-bold'>{title}</h2>
            </div>
        </div>

    );
};

export default SubjectTitle;