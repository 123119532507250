import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert, Modal } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import '../css/ImmigrationForm.css';
import QRCode from 'react-qr-code';
import MyToast from './MyToast';
import { initiateMesombPayment } from '../services/api';
import MySpinner from './MySpinner';
import InfoModal from './Modal/InfoModal';



const ImmigrationForm = () => {

    const [formData, setFormData] = useState({
        fullName: '',
        gender: 'Homme',
        country: 'Cameroon',
        email: '',
        phone: '',
        paymentMethod: 'orange'
    });

    const h4Style = {
        fontFamily: 'Arial, sans-serif',
        fontSize: '18px',
        fontWeight: 'bold',
    };

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [visible, setVisible] = React.useState(false);
    const [msg, setMsg] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [img, setImg] = React.useState(null);
    const [paymentDone, setPaymentDone] = useState(false);
    const [payment, setPayment] = useState({})
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });


    const [subscriptionDetails, setSubscriptionDetails] = useState({
        amountPaid: 0,
        paymentDate: null,
        validityPeriod: '2 months',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCheckboxChange = () => {
        setTermsAccepted(!termsAccepted);
    };

    const handleShowInfo = () => {
        setShowInfoModal(true);
    };

    const handleCloseInfo = () => {
        setShowInfoModal(false);
    };


    const calculateSubscriptionEndDate = (paymentDate) => {
        const startDate = new Date(paymentDate);
        const endDate = new Date(startDate.setMonth(startDate.getMonth() + 2));
        return endDate.toLocaleDateString('fr-FR');
    };

    const closeModal = () => {
        setVisible(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.fullName || !formData.phone || !formData.paymentMethod || !formData.paymentNumber) {
            setOpenToast({
                open: true,
                msg: 'Veuillez remplir tous les champs obligatoires',
                severity: 'error'
            })

            return;
        }

        // Construire l'objet de données à envoyer à l'API
        const requestData = {
            name: formData.fullName,
            sex: formData.gender,
            country: formData.country,
            email: formData.email,
            whatsAppNumber: formData.phone,
            quantity: 1,
            paymentMethod: formData.paymentMethod,
            paymentNumber: formData.paymentNumber,
            subscriptionDate: new Date(),
            subscriptionEndDate: new Date((new Date()).setMonth((new Date()).getMonth() + 2)),
            status: 'PENDING'
        };

        setLoading(true);

        try {
            const response = await fetch('https://5w8nvzw507.execute-api.us-east-2.amazonaws.com/v1/saveConsultation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                setOpenToast({
                    open: true,
                    msg: "Une erreur s'est produite lors de la souscription. Veuillez réessayer ultérieurement.",
                    severity: 'error'
                })
                setLoading(false);

                return;
            }


            const responseData = await response.json();
            setPayment(responseData.data)

            setTitle('Validation du paiement')
            setImg("../assets/img/payment-sms.png")
            setMsg('Pour valider le paiement veuillez suivre les instructions du SMS que vous allez recevoir sur votre mobile ');
            setVisible(true);

            setTimeout(() => {
                closeModal();
            }, 10000);

            const payment = await initiateMesombPayment(responseData.data._id, 10000, formData.paymentMethod, formData.paymentNumber, formData.country);

            setPaymentDone(true);
            setLoading(false);

            //mise à jour du status du paiement
            axios.put('https://5w8nvzw507.execute-api.us-east-2.amazonaws.com/v1/updateConsultation', JSON.stringify({
                consultationId: responseData.data._id,
                updatedFields: {
                    status: 'PAID'
                }
            }));

            axios.post('https://tsscu51gn5.execute-api.us-east-2.amazonaws.com/v1/sendmailwithmailjet', JSON.stringify({
                from: 'services@exams-tcfcanada.com',
                to_email: 'mfi.tene@gmail.com',
                to_name: 'Marius',
                from_name: 'TCF CANADA',
                subject: "Souscription à l'offre d'accompagnement",
                text: "Un client vient de souscrire à l'offre de consultation pour l'immigration. Les details: \n\n" + JSON.stringify({
                    name: formData.fullName,
                    sex: formData.gender,
                    country: formData.country,
                    email: formData.email,
                    whatsAppNumber: formData.phone,
                    quantity: 1,
                    paymentMethod: formData.paymentMethod,
                    paymentNumber: formData.paymentNumber,
                    subscriptionDate: new Date(),
                    subscriptionEndDate: new Date((new Date()).setMonth((new Date()).getMonth() + 2))
                })
            }));

        } catch (error) {
            console.log(error.code);
            if (error.code === 'operation-cannot-performed') {
                setOpenToast({
                    open: true,
                    msg: "Une erreur s'est produite lors de la souscription. Veuillez vérifier votre solde et si le problème persiste veuillez réessayer ultérieurement.",
                    severity: 'error'
                })
            }
            else {
                setOpenToast({
                    open: true,
                    msg: "Une erreur s'est produite lors de la souscription. Veuillez réessayer ultérieurement.",
                    severity: 'error'
                })
            }

            setLoading(false);

            return;

        }
    };


    const PaymentSuccess = () => (
        <div className="payment-success mt-5" >


            <Alert variant="success" className='mb-5 mt-6'>
                <h3>Paiement effectué avec succès!</h3>
                Merci pour votre souscription. Un de nos agents prendra contact avec vous dans les plus brefs délais via WhatsApp.
                N'oubliez pas de télécharger votre reçu.
            </Alert>

            <div className='row'>
                <Button variant="primary" className='col-auto mr-2' onClick={handlePrint} style={{ marginLeft: 'auto' }}>
                    Télécharger le reçu
                </Button>
            </div>


            <div ref={componentRef} className='print'>
                <h3 className="text-center"><b>Confirmation de paiement</b></h3>
                <p className="text-center">{payment.paymentReference}</p>

                <div className="text-center my-3" style={{ height: "auto", margin: "0 auto", maxWidth: 100, width: "100%" }}>
                    <QRCode
                        size={400}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={
                            `https://exams-tcfcanada.com/check-subscription?source=ref&value=${payment.paymentReference}\n
                             https://exams-tcfcanada.com/check-subscription?source=phone&value=${payment.whatsAppNumber}
                            `
                        }
                        viewBox={`0 0 300 300`}
                    />
                </div>

                <h4 style={h4Style}>
                    Abonnement Premium à la Consultation en Immigration pour le Canada en Ligne
                </h4>
                <a href='https://www.exams-tcfcanada.com'>
                    https://www.exams-tcfcanada.com
                </a>
                <table className="recap-table">
                    <tbody>
                        <tr>
                            <td>Nom du client:</td>
                            <td>{payment.name}</td>
                        </tr>
                        <tr>
                            <td>Sexe:</td>
                            <td>{payment.sex}</td>
                        </tr>

                        <tr>
                            <td>Pays:</td>
                            <td>{payment.country}</td>
                        </tr>

                        <tr>
                            <td>Numéro WhatsApp:</td>
                            <td>{payment.whatsAppNumber}</td>
                        </tr>

                        <tr>
                            <td>Montant payé:</td>
                            <td>10 000 XAF</td>
                        </tr>
                        <tr>
                            <td>Date de paiement:</td>
                            <td>{(new Date(payment.subscriptionDate)).toLocaleDateString('fr-FR')}</td>
                        </tr>
                        <tr>
                            <td>Date de fin de la souscription:</td>
                            <td>{(new Date(payment.subscriptionEndDate)).toLocaleDateString('fr-FR')}</td>
                        </tr>

                        <tr>
                            <td>Méthode de paiement:</td>
                            <td>{payment.paymentMethod}</td>
                        </tr>

                        <tr>
                            <td>Numéro de paiement:</td>
                            <td>{payment.paymentNumber}</td>
                        </tr>
                    </tbody>
                </table>

                <Alert variant="info" className='fs--2 mt-2 alert'>
                    <b> <u>Contenu de l'offre:</u></b>
                    <ul>
                        <li>Présentation des différents programmes d'immigration</li>
                        <li>Assistance pour choisir le meilleur programme selon votre profil</li>
                        <li>Liste des étapes à suivre et des principaux documents nécessaires</li>
                        <li>Estimation du montant total de votre procédure</li>
                        <li>Fourniture de liens importants pour compléter vos informations</li>
                        <li>Orientations vers des professionnels pouvant vous aider étape par étape à moindre coût</li>
                    </ul>
                </Alert>
            </div>

            <Button variant="primary" className='mt-3' onClick={handlePrint}>
                Télécharger le reçu
            </Button>
        </div>
    );

    return (
        <Container>
            <Row className="justify-content-center">
                <Col md={8}>
                    {paymentDone ? (
                        <PaymentSuccess />
                    ) : (
                        <>
                            <h2 className="mt-6 mb-4">Services de consultation en ligne pour l'immigration au Canada.</h2>

                            <p>
                                Vous souhaitez immigrer au Canada avec votre famille, mais vous ne savez pas par où commencer? Nous pouvons vous guider à distance grâce à notre service Premium de Consultation en Immigration pour le Canada.
                                <a href="#" onClick={handleShowInfo}>
                                    <span> </span> Pour en savoir plus sur cette offre, veuillez cliquez ici.
                                </a>
                            </p>

                            <Modal show={showInfoModal} onHide={handleCloseInfo}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Offre Premium à la Consultation en Immigration pour le Canada</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Alert variant="info">
                                        Pour démarrer votre projet d'immigration au Canada, bénéficiez de notre service d'assistance personnalisée via
                                        {' '}
                                        <span style={{ color: '#25D366' }}>WhatsApp</span>,
                                        moyennant des frais de 10 000 XAF, à régler lors de la souscription à cette offre exclusive.
                                        <p></p>
                                        Plus spécifiquement, nous sommes en mesure de vous accompagner sur les points essentiels suivants :
                                        <p></p>
                                        <ul>
                                            <li>Présentation des différents programmes d'immigration</li>
                                            <li>Assistance pour choisir le meilleur programme selon votre profil</li>
                                            <li>Liste des étapes à suivre et des principaux documents nécessaires</li>
                                            <li>Estimation du montant total de votre procédure</li>
                                            <li>Fourniture de liens importants pour compléter vos informations</li>
                                            <li>Orientations vers des professionnels pouvant vous aider étape par étape à moindre coût</li>
                                        </ul>
                                    </Alert>

                                    <Alert variant="secondary">
                                        L'assistance en ligne est disponible chaque jour entre 09h du matin et 18h du soir.
                                        De plus, l'offre est valide pour une durée de 2 mois à compter de la date de souscription.
                                    </Alert>

                                    <Alert variant="danger">
                                        <strong>Importante :</strong> La souscription en ligne pour cette offre
                                        n'est accessible qu'aux Camerounais pour le moment. Les personnes des autres pays
                                        intéressées peuvent nous contacter via{' '}
                                        <a href="/contactez-nous" style={{ color: '#FF0000' }}>
                                            notre formulaire de contact
                                        </a>
                                        .
                                        <p>
                                            Veuillez noter que cette offre n'implique pas que nous devions réaliser votre procédure d'immigration nous-mêmes. Elle ne concerne que les points susmentionnés.
                                        </p>
                                    </Alert>



                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseInfo}>
                                        Fermer
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Form onSubmit={handleSubmit} className='mt-5'>
                                <h3>Formulaire de souscription à l'offre</h3>
                                {/* Form fields */}
                                <Form.Group controlId="fullName" className='mb-3 mt-4'>
                                    <Form.Label>Votre nom complet <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="fullName"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                        required={true}
                                    />
                                </Form.Group>

                                <Form.Group controlId="gender" className='mb-3'>
                                    <Form.Label>Sexe <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="gender"
                                        required={true}
                                        value={formData.gender}
                                        onChange={handleChange}
                                    >
                                        <option value="Masculin">Homme</option>
                                        <option value="Féminin">Femme</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="country" className='mb-3'>
                                    <Form.Label>Pays <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="country"
                                        required={true}
                                        value={formData.country}
                                        onChange={handleChange}
                                        disabled
                                    >
                                        <option value="Cameroon">Cameroun</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="email" className='mb-3'>
                                    <Form.Label>Adresse Email (optionnel)</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="phone" className='mb-3'>
                                    <Form.Label>Numéro WhatsApp <span style={{ color: 'red' }}>*</span></Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="phone"
                                        placeholder='Exemple: 691402415'
                                        required={true}
                                        style={{ opacity: 0.8 }}
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <div className="mt-6">
                                    <h3>Paiement</h3>
                                    <p>
                                        Veuillez vous acquitter de la somme de <span style={{ color: '#007BFF', fontWeight: 'bold' }}>10 000 XAF</span> pour finaliser le processus d'enregistrement.
                                    </p>

                                    <Form.Group controlId="paymentMethod" className='mb-3 mt-4'>
                                        <Form.Label>Méthode de Paiement <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control as="select" name="paymentMethod"
                                            value={formData.paymentMethod}
                                            onChange={handleChange}
                                        >
                                            <option value="orange">Orange Money</option>
                                            <option value="mtn">MTN Money</option>
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group controlId="paymentNumber" className='mb-3'>
                                        <Form.Label>Entrez votre numéro de paiement <span style={{ color: 'red' }}>*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            name="paymentNumber"
                                            value={formData.paymentNumber}
                                            onChange={handleChange}
                                            required={true}
                                            placeholder='Exemple: 691402415'
                                            style={{ opacity: 0.8 }}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="termsCheckbox" className='mb-3'>
                                        <Form.Check
                                            type="checkbox"
                                            label="J'ai lu et j'accepte les termes de l'offre"
                                            checked={termsAccepted}
                                            required={true}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Form.Group>

                                    <Button variant="success" type='submit' >
                                        Payer et souscrire
                                    </Button>

                                </div>

                            </Form>

                        </>
                    )}
                </Col>

            </Row>

            <InfoModal
                visible={visible}
                title={title}
                msg={msg}
                setVisible={setVisible}
                img={img}
            />

            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={loading} />
        </Container>
    );
};

export default ImmigrationForm;
