import React from 'react';


function TextWithBold({ text }) {
  // Utilisation d'une expression régulière pour trouver les parties entre '**' et les mettre en gras
  const parts = text.split(/\*\*(.*?)\*\*/g);
  const formattedText = parts.map((part, index) => {
    if (index % 2 === 1) {
      // Les parties impaires sont celles qui doivent être en gras
      return <strong key={index}>{part}</strong>;
    }
    return part;
  });

  return <>{formattedText}</>;
}



function TextWithBoldFormatting({ text }) {

  return (
    <>
      <TextWithBold text={text} />
    </>
  );
}

export default TextWithBoldFormatting;
