import * as React from "react";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import MyToast from './MyToast';
import MySpinner from './MySpinner';


const Login = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const state = location.state;

    const msg = state?.msg;
    const redirectPath = state?.redirectPath;


    const [loginData, setLoginData] = React.useState({});
    const [inProgress, setinProgress] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });



    const handleChange = e => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    }


    React.useEffect(() => {
        if (msg) {
            setOpenToast({
                open: true,
                msg: msg,
                severity: 'info'
            })
        }
    }, [])


    const handleSubmit = async (event) => {
        event.preventDefault();

        //vérifions les paramètres requis
        let { email, password } = loginData;
        if (!email || !password) {
            setOpenToast({
                open: true,
                msg: "Veuillez renseigner votre adresse email et mot de passe",
                severity: "error"
            })
            return;
        }

        try {

            setinProgress(true);

            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            setOpenToast({
                open: true,
                msg: "Connexion réussie",
                severity: "success"
            })

            setinProgress(false);

            navigate('/accueil');

        } catch (error) {
            setinProgress(false);
            const errorCode = error?.code;
            const errorMessage = error?.message;

            if (errorCode === 'auth/wrong-password') {
                setOpenToast({
                    open: true,
                    msg: "Le mot de passe est invalide",
                    severity: "error"
                })
            }
            else if (errorCode === 'auth/user-not-found') {
                setOpenToast({
                    open: true,
                    msg: "Le compte spécifié est introuvable",
                    severity: "error"
                })
            }
            else if (errorCode === 'auth/network-request-failed') {
                setOpenToast({
                    open: true,
                    msg: "Vérifiez votre connexion internet",
                    severity: "error"
                })
            }
            else if (errorCode === 'auth/too-many-requests') {
                setOpenToast({
                    open: true,
                    msg: "Vous avez éffectuer trop de tentative. Pour des raisons de sécurité, vous devez modifier votre mot de passe ou vous connecter plus tard",
                    severity: "error"
                })
            }
            else {
                setOpenToast({
                    open: true,
                    msg: "Une erreur s'est produite, veuillez réessayer ultérieurement",
                    severity: "error"
                })
            }


        }

    };

    return (
        <div className="row mx-0 px-0">
            <div className="col-sm-1 col-md-1 col-lg-2 col-xxl-3"></div>
            <div className="col-sm-10 col-md-10 col-lg-8 col-xxl-6">

                <div className="card login-form">
                    <div className="card-body p-4 p-sm-5">
                        <div className="row text-left justify-content-between align-items-center mb-2">
                            <div className="col-auto">
                                <h2> Connexion</h2>
                            </div>
                            <div className="col-auto mt-3">
                                <p className="fs--1 text-600 mb-2">Pas encore de compte ?
                                    <NavLink className='ml-1' to="/inscription/email" >
                                        Inscription
                                    </NavLink>
                                </p>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit} className="customForm mt-3">

                            <div className="form-field animation a3">
                                <label>Entrer votre adresse E-mail</label>
                                <input type="email"
                                    name='email'
                                    id="email"
                                    onChange={handleChange}
                                    required />
                            </div>

                            <div className="form-field animation a3">
                                <label>Mot de passe</label>
                                <input type="password"
                                    name='password'
                                    id="password"
                                    onChange={handleChange}
                                    required />
                            </div>

                            <div className="row justify-content-between align-items-center">
                                <div className="col-auto"></div>
                                <div className="col-auto">
                                    <NavLink className='ml-1 fs--1' to="/mot-de-passe-oublie" >
                                        Mot de passe oublié
                                    </NavLink>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <button className="btn btn-block mt-3" type="submit" name="submit" style={{ backgroundColor: '#2b3035', color: 'white' }}>Connexion</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />

        </div>

    );
};

export default Login;