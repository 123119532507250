import React, { useState } from 'react';


const OptionQuestion = ({ option, questionNumber, handleOptionChange, checked, disabled}) => {

    return (
        <>
            <div className="custom-control custom-radio mb-2" style={{
                border: '2px solid #b5bcc3',
                borderRadius: 10,
                paddingRight: '0.7rem',
                paddingLeft: '2rem',
                paddingTop: '0.6rem',
                paddingBottom: '0.8rem',
                width: '100%',
                marginLeft: '1.1px',
                boxShadow: '0 2px 4px rgb(0, 0, 0, 0.1)'
            }}>
                <input className="custom-control-input w-100 pt-3"
                    onChange={e => handleOptionChange(option.alpha)}
                    id={`option${option.alpha}${questionNumber}`}
                    type="radio" name={`customRadio${questionNumber}`}
                    defaultChecked={checked}
                    disabled={disabled}
                    style={{ cursor: 'pointer' }}
                />

                <label className="custom-control-label w-100" htmlFor={`option${option.alpha}${questionNumber}`} style={{ cursor: 'pointer' }}>
                    <span className='fs-5 font-weight-bold mr-5'>{option.alpha}</span>
                    <span className=''>{option.label}</span>
                </label>
            </div>
        </>
    );
};

export default OptionQuestion;