import React from 'react';
import Menu from '../components/Navbar';
import Container from 'react-bootstrap/Container';
import Footer from '../components/Footer';
import Pricing from '../components/Pricing';
import CustomTitle from '../components/CustomTitle';

const P_Offers = () => {
    return (
        <>
            <Menu />
            <div style={{ marginTop: '14.5rem' }}></div>
            <Container className=''>

                <CustomTitle
                    part1={'NOS FORFAITS '}
                    part2={'POUR VOUS FORMER'}
                    part3={' CHEZ NOUS'}
                    description={"Faites vos choix, abonnez-vous, et commencez aujourd'hui"}
                />
                <div style={{ marginTop: '2rem' }}></div>

                <Pricing />
            </Container>
            <div style={{ marginTop: '4rem' }}></div>
            <Footer />
        </>
    );
};

export default P_Offers;