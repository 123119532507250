import * as React from 'react';
import OptionQuestion from './OptionQuestion';


function checkIfIsChecked(questions_rep, order, option) {
    const quest = questions_rep[order - 1];
    if (!quest) return false;
    if (quest.repondu === false) return false;

    if (quest.position_choisi === option.alpha) return true;

    return false;
}



const DisplaySubject = ({ order, options, questions_rep, setQuestions_rep, mode}) => {

    const handleOptionChange = (val) => {

        const new_rep = [];
        let tmp = questions_rep[order - 1];
        tmp.repondu = true;
        if (tmp.position_rep === val) {
            tmp.trouve = true;
        }
        else {
            tmp.trouve = false;
        }

        tmp.position_choisi = val;

        for (const rp of questions_rep) {
            if (rp.order !== order) {
                new_rep.push(rp);
            }
            else {
                new_rep.push(tmp);
            }
        }

        setQuestions_rep(new_rep);
    }



    return (
        <div className='my-5 '>

            <div>

                {
                    options.map((option) => (

                        <OptionQuestion
                            option={option}
                            checked={checkIfIsChecked(questions_rep, order, option)}
                            questionNumber={order}
                            disabled={mode !== 'exams'}
                            handleOptionChange={handleOptionChange}
                        />

                    ))
                }

            </div>

            {
                (mode !== 'exams') ? (
                    <div className="custom-control custom-radio mb-2" style={{
                        border: '2px solid #b5bcc3',
                        borderRadius: 10,
                        paddingRight: '0.7rem',
                        paddingLeft: '2rem',
                        paddingTop: '0.6rem',
                        backgroundColor: '#f0f3f6',
                        paddingBottom: '0.8rem',
                        width: '100%',
                        marginLeft: '1.1px',
                        boxShadow: '0 2px 4px rgb(0, 0, 0, 0.1)'
                    }}>
                        <label className=" w-100" htmlFor={``} >
                            {
                                (questions_rep[order - 1]?.trouve === true) ? (
                                    <span style={{ color: '#81d742' }}>Correct</span>
                                ) : (
                                    <span style={{ color: '#dd3345' }}>Incorrect, la bonne réponse est {questions_rep[order-1].position_rep}</span>
                                )
                            }
                        </label>
                    </div>
                ) : null
            }
        </div>
    );
};

export default DisplaySubject;



{/* <FormControl className='w-100'>
            <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                className='w-100'
            >
                <FormControlLabel style={{
                    border: '2px solid #666666',
                    borderRadius: 10,
                    paddingRight:'0.7rem',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    width: '100%',
                    marginLeft: '1.1px',
                    boxShadow: '0 2px 4px rgb(0, 0, 0, 0.1)'
                }} value="female" control={<Radio />} label="Female jbds bdshbf shjfbdsj sjdhbfsd jhsdbfdsh jhbfds" />
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup>
        </FormControl> */}