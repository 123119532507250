import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebase';
import Cookies from 'js-cookie';
import { addUserToken } from '../services/api';

const AuthContext = createContext();

export default function FirebaseAuthContext({ children }) {

    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (authUser) => {
            if (authUser) {
                setUser(authUser)

                const token = Cookies.get('notif_token');

                if(token) {
                    addUserToken({userID: authUser?.uid, token: token});
                }

            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, [])

    return (
        <AuthContext.Provider value={{user}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
}
