import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const InfoModal = ({ visible, setVisible, title, msg, img }) => {

    const handleOK = () => {
        setVisible(false);
    };

    return (
        <Modal show={visible} onHide={handleOK}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {
                    img ? (
                        <div className='col-12 d-flex justify-content-center mb-3'>
                            <img classname="" style={{ height: '300px' }} loading='lazy' src={img} alt />
                        </div>
                    ) : null
                }

                <span className='font-weight-semi-bold'>{msg}</span>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleOK}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InfoModal;