import React from 'react';
import Footer from '../components/Footer';
import MyToast from '../components/MyToast';
import LoadingOverlay from 'react-loading-overlay';
import Container from 'react-bootstrap/Container';
import { useNavigate, useSearchParams } from "react-router-dom";
import AddExpressionSubject from '../components/Admin/AddExpressionSubject';
import AdminMenu from '../components/Admin/AdminMenu';
import Dashbord from '../components/Admin/Dashbord';
import CreateOffer from '../components/Admin/CreateOffer';
import DeleteOffer from '../components/Admin/DeleteOffer';
import Coupons from '../components/Admin/Coupons';
import SendNotif from '../components/Admin/SendNotif';



const P_Admin = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    let service = searchParams.get('service') || null;

    const [serviceID, setServiceID] = React.useState(service);
    const [user, setUser] = React.useState(null);
    const [progress, setProgress] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });


    React.useEffect(() => {

        if (!service) {
            navigate('/error');
            return;
        }

        setServiceID(service);

    }, [service, navigate])


    return (
        <>

            <AdminMenu />

            <LoadingOverlay
                active={progress}
                spinner
                className='fs-md-0 fs--1'
                text=''>

                <Container className='pb-2'>


                    <div style={{ marginTop: '4rem' }}></div>

                    {
                        (serviceID === 0 || serviceID == '0') ? (
                            <Dashbord
                                setProgress={setProgress}
                            />
                        ) : null
                    }

                    {
                        (serviceID === 1 || serviceID == '1') ? (
                            <AddExpressionSubject
                                setProgress={setProgress}
                                setOpenToast={setOpenToast}
                            />
                        ) : null
                    }

                    {
                        (serviceID === 2 || serviceID == '2') ? (
                            <CreateOffer
                                setProgress={setProgress}
                                setOpenToast={setOpenToast}
                            />
                        ) : null
                    }

                    {
                        (serviceID === 3 || serviceID == '3') ? (
                            <DeleteOffer
                                setProgress={setProgress}
                                setOpenToast={setOpenToast}
                            />
                        ) : null
                    }

                    {
                        (serviceID === 4 || serviceID == '4') ? (
                            <Coupons
                                setProgress={setProgress}
                            />
                        ) : null
                    }


                    {
                        (serviceID === 5 || serviceID == '5') ? (
                            <SendNotif
                                setProgress={setProgress}
                                setOpenToast={setOpenToast}
                            />
                        ) : null
                    }


                    <MyToast
                        openToast={openToast}
                        setOpenToast={setOpenToast}
                    />
                </Container>

                <Footer />

            </LoadingOverlay>

        </>
    );
};

export default P_Admin;

