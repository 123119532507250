import React, { useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { Divider, Switch } from '@mui/material';
import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon
} from 'react-share';


function getNCLCLevel_CELPIP(subject, score) {
    const nclcTable = [
        { NCLC: 10, comprehensionEcrit: 10, expressionEcrite: 10, comprehensionOral: 10, expressionOrale: 10 },
        { NCLC: 9, comprehensionEcrit: 9, expressionEcrite: 9, comprehensionOral: 9, expressionOrale: 9 },
        { NCLC: 8, comprehensionEcrit: 8, expressionEcrite: 8, comprehensionOral: 8, expressionOrale: 8 },
        { NCLC: 7, comprehensionEcrit: 7, expressionEcrite: 7, comprehensionOral: 7, expressionOrale: 7 },
        { NCLC: 6, comprehensionEcrit: 6, expressionEcrite: 6, comprehensionOral: 6, expressionOrale: 6 },
        { NCLC: 5, comprehensionEcrit: 5, expressionEcrite: 5, comprehensionOral: 5, expressionOrale: 5 },
        { NCLC: 4, comprehensionEcrit: 4, expressionEcrite: 4, comprehensionOral: 4, expressionOrale: 4 },
    ];

    const subjectLowerCase = subject;
    const matchingRow = nclcTable.find((row) => row[subjectLowerCase] === parseFloat(score));

    if (matchingRow) {
        return matchingRow.NCLC;
    } else {
        return "0";
    }
}

function calculerNCLC_IELTS(matiere, note) {
    const tableauNCLC = [
        { NCLC: 10, comprehensionEcrit: 8.0, expressionEcrite: 7.5, comprehensionOral: 8.5, expressionOrale: 7.5 },
        { NCLC: 9, comprehensionEcrit: 7.0, expressionEcrite: 7.0, comprehensionOral: 8.0, expressionOrale: 7.0 },
        { NCLC: 8, comprehensionEcrit: 6.5, expressionEcrite: 6.5, comprehensionOral: 7.5, expressionOrale: 6.5 },
        { NCLC: 7, comprehensionEcrit: 6.0, expressionEcrite: 6.0, comprehensionOral: 6.0, expressionOrale: 6.0 },
        { NCLC: 6, comprehensionEcrit: 5.0, expressionEcrite: 5.5, comprehensionOral: 5.5, expressionOrale: 5.5 },
        { NCLC: 5, comprehensionEcrit: 4.0, expressionEcrite: 5.0, comprehensionOral: 5.0, expressionOrale: 5.0 },
        { NCLC: 4, comprehensionEcrit: 3.5, expressionEcrite: 4.0, comprehensionOral: 4.5, expressionOrale: 4.0 },
    ];

    // Recherche de la ligne correspondante dans le tableau en fonction de la matière et de la note
    const ligneCorrespondante = tableauNCLC.find((ligne) => ligne[matiere] === parseFloat(note));

    if (ligneCorrespondante) {
        return ligneCorrespondante.NCLC;
    } else {
        if(parseFloat(note) > 8.5 && (matiere === 'comprehensionEcrit' || matiere === 'comprehensionOral')) return 10;
        if(parseFloat(note) > 7.5 && (matiere === 'expressionEcrite' || matiere === 'expressionOrale')) return 10
        return "0"; 
    }
}

function getNCLCLevelTEF(subject, score) {
    const tableData = [
        [10, [263, 300], [393, 450], [316, 360], [393, 450]],
        [9, [248, 262], [371, 392], [298, 315], [371, 392]],
        [8, [233, 247], [349, 370], [280, 297], [349, 370]],
        [7, [207, 232], [310, 348], [249, 279], [310, 348]],
        [6, [181, 206], [271, 309], [217, 248], [271, 309]],
        [5, [151, 180], [226, 270], [181, 216], [226, 270]],
        [4, [121, 150], [181, 225], [145, 180], [181, 225]]
    ];

    const columnIndex = {
        'comprehensionEcrit': 1,
        'expressionEcrite': 2,
        'comprehensionOral': 3,
        'expressionOrale': 4
    };

    const subjectIndex = columnIndex[subject];
    if (subjectIndex === undefined) {
        return 'Matière non valide';
    }

    for (let i = 0; i < tableData.length; i++) {
        const row = tableData[i];
        const scoreRange = row[subjectIndex];
        const minScore = scoreRange[0];
        const maxScore = scoreRange[1];

        if (score >= minScore && score <= maxScore) {
            return row[0];
        }
    }

    return '0';
}

function getNCLCLevel(subject, score) {
    const nclcTable = [
        { level: '10 +', comprehensionEcrit: '549-699', expressionEcrite: '16-20', comprehensionOral: '549-699', expressionOrale: '16-20' },
        { level: '9', comprehensionEcrit: '524-548', expressionEcrite: '14-15', comprehensionOral: '523-548', expressionOrale: '14-15' },
        { level: '8', comprehensionEcrit: '499-523', expressionEcrite: '12-13', comprehensionOral: '503-522', expressionOrale: '12-13' },
        { level: '7', comprehensionEcrit: '453-498', expressionEcrite: '10-11', comprehensionOral: '458-502', expressionOrale: '10-11' },
        { level: '6', comprehensionEcrit: '406-452', expressionEcrite: '7-9', comprehensionOral: '398-457', expressionOrale: '7-9' },
        { level: '5', comprehensionEcrit: '375-405', expressionEcrite: '6', comprehensionOral: '369-397', expressionOrale: '6' },
        { level: '4', comprehensionEcrit: '342-374', expressionEcrite: '4-5', comprehensionOral: '331-368', expressionOrale: '4-5' },
    ];

    const matchingRow = nclcTable.find((row) => {
        const subjectScore = row[subject];
        if (subjectScore) {
            const [min, max] = subjectScore.split('-');
            return score >= parseInt(min) && score <= parseInt(max);
        }
        return false;
    });

    return matchingRow ? matchingRow.level : '0';
}


function CalculatriceNcLc() {

    const [loading, setLoading] = React.useState(false);
    const [comprehensionOrale, setComprehensionOrale] = useState(0);
    const [comprehensionEcrite, setComprehensionEcrite] = useState(0);
    const [expressionOrale, setExpressionOrale] = useState(0);
    const [expressionEcrite, setExpressionEcrite] = useState(0);
    const [nclcFinal, setNclcFinal] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [examen, setExamen] = useState('TCF CANADA');

    const [nclcFinalco, setNclcFinalco] = useState(0);
    const [nclcFinalce, setNclcFinalce] = useState(0);
    const [nclcFinaleo, setNclcFinaleo] = useState(0);
    const [nclcFinalee, setNclcFinalee] = useState(0);


    React.useEffect(() => {
        setNclcFinalco(0);
        setNclcFinalce(0);
        setNclcFinaleo(0);
        setNclcFinalee(0);
        setShowAlert(false);
        setNclcFinal(0);

        setComprehensionEcrite(0);
        setComprehensionOrale(0);
        setExpressionOrale(0);
        setExpressionEcrite(0);
    }, [examen])

    const handleExamenChange = (e) => {
        setExamen(e.target.value);
    };

    React.useEffect(() => {
        const nclcLevel = getScore('comprehensionOral', comprehensionOrale);
        setNclcFinalco(nclcLevel);
    }, [comprehensionOrale])


    React.useEffect(() => {
        const nclcLevel = getScore('comprehensionEcrit', comprehensionEcrite);
        setNclcFinalce(nclcLevel);
    }, [comprehensionEcrite])


    React.useEffect(() => {
        const nclcLevel = getScore('expressionOrale', expressionOrale);
        setNclcFinaleo(nclcLevel);
    }, [expressionOrale])

    React.useEffect(() => {
        const nclcLevel = getScore('expressionEcrite', expressionEcrite);
        setNclcFinalee(nclcLevel);
    }, [expressionEcrite])

    const getScore = (subj, score_) => {

        if (examen === 'TCF CANADA') return getNCLCLevel(subj, score_);
        if (examen === 'TEF CANADA') return getNCLCLevelTEF(subj, score_);
        if (examen === 'IELTS') return calculerNCLC_IELTS(subj, score_);
        if (examen === 'CELPIP') return getNCLCLevel_CELPIP(subj, score_);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (comprehensionOrale > 0 && comprehensionEcrite > 0 && expressionOrale > 0 && expressionEcrite > 0) {
            const nclcLevels = [
                getScore('comprehensionOral', comprehensionOrale),
                getScore('comprehensionEcrit', comprehensionEcrite),
                getScore('expressionOrale', expressionOrale),
                getScore('expressionEcrite', expressionEcrite),
            ];

            // Trouver le niveau NCLC minimum parmi les différentes matières
            const minNCLC = Math.min(...nclcLevels.map((level) => parseInt(level)));

            setNclcFinal(minNCLC);
            setShowAlert(false);
        } else {
            setShowAlert(true);
        }
    };

    const shareUrl = 'https://www.exams-tcfcanada.com/calculatrice-nclc';
    const shareText = `*Mes résultats à l\'examen ${examen}*\n\nCO: NCLC de ${nclcFinalco}\nCE: NCLC de ${nclcFinalce}\nEO: NCLC de ${nclcFinaleo}\nEE: NCLC de ${nclcFinalee}\n\n*NCLC GLOBAL*: ${nclcFinal}\n\n\nDétermine ton NCLC sur\n${shareUrl}`;

    return (
        <LoadingOverlay
            active={loading}
            spinner
            className='fs-md-0 fs--1'
            text=''>

            <Container style={{ backgroundColor: "#F5F5F5" }} className='py-3' >
                <Row>
                    <Col xs={12} md={6} lg={6} className="mx-auto p-2" >
                        <div className="text-center mb-5">
                            <h4><b>CALCULATRICE NCLC</b></h4>
                        </div>

                        <Form.Group as={Row} controlId="examen" className="mx-auto text-center">
                            <Form.Label column xs="auto text-primary">
                                CHOISIR L'EXAMEN
                            </Form.Label>
                            <Col xs="auto">
                                <Form.Select
                                    value={examen}
                                    onChange={handleExamenChange}
                                >
                                    <option value="TCF CANADA">TCF CANADA</option>
                                    <option value="TEF CANADA">TEF CANADA</option>
                                    <option value="IELTS">IELTS</option>
                                    <option value="CELPIP">CELPIP</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>

                        <div style={{ marginBottom: '3rem' }}></div>

                        <Divider />

                        <Form onSubmit={handleSubmit}>
                            <Form.Group as={Row} controlId="comprehensionOrale">
                                <Form.Label column xs={9}>
                                    Compréhension orale
                                </Form.Label>
                                <Col xs={5} md={6} lg={6}>
                                    <Form.Control
                                        type="number"
                                        value={comprehensionOrale}
                                        onChange={(e) => setComprehensionOrale(e.target.value)}
                                    />
                                </Col>
                                <Col xs={5} md={4} lg={4}>
                                    <Button disabled variant="danger" >NCLC - CO</Button>
                                </Col>
                                <Col xs={2} md={2} lg={2} className='pt-2' style={{ fontWeight: 'bold' }}>
                                    <span>{nclcFinalco}</span>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="comprehensionEcrite">
                                <Form.Label column xs={9}>
                                    Compréhension écrite
                                </Form.Label>
                                <Col xs={5} md={6} lg={6}>
                                    <Form.Control
                                        type="number"
                                        value={comprehensionEcrite}
                                        onChange={(e) => setComprehensionEcrite(e.target.value)}
                                    />
                                </Col>
                                <Col xs={5} md={4} lg={4}>
                                    <Button disabled variant="danger"  >NCLC - CE</Button>
                                </Col>
                                <Col xs={2} md={2} lg={2} className='pt-2' style={{ fontWeight: 'bold' }}>
                                    <span>{nclcFinalce}</span>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="expressionOrale">
                                <Form.Label column xs={9}>
                                    Expression Orale
                                </Form.Label>
                                <Col xs={5} md={6} lg={6}>
                                    <Form.Control
                                        type="number"
                                        value={expressionOrale}
                                        onChange={(e) => setExpressionOrale(e.target.value)}
                                    />
                                </Col>
                                <Col xs={5} md={4} lg={4} >
                                    <Button disabled variant="danger" >NCLC - EO</Button>
                                </Col>
                                <Col xs={2} md={2} lg={2} className='pt-2' style={{ fontWeight: 'bold' }}>
                                    <span>{nclcFinaleo}</span>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="expressionEcrite">
                                <Form.Label column xs={9}>
                                    Expression Ecrite
                                </Form.Label>
                                <Col xs={5} md={6} lg={6}>
                                    <Form.Control
                                        type="number"
                                        value={expressionEcrite}
                                        onChange={(e) => setExpressionEcrite(e.target.value)}
                                    />
                                </Col>
                                <Col xs={5} md={4} lg={4}>
                                    <Button disabled variant="danger"  >NCLC - EE</Button>
                                </Col>
                                <Col xs={2} md={2} lg={2} className='pt-2' style={{ fontWeight: 'bold' }}>
                                    <span>{nclcFinalee}</span>
                                </Col>
                            </Form.Group>

                            <Button
                                type="submit"
                                variant="success"
                                className="mx-auto d-block mt-5"
                            >
                                CALCUL NCLC FINAL
                            </Button>

                            {showAlert && (
                                <Alert variant="danger" className="text-center mt-3">
                                    Veuillez remplir tous les champs précédents.
                                </Alert>
                            )}

                            <div className="text-center mt-4">
                                <h5>Votre NCLC final est égal à : {nclcFinal}</h5>
                            </div>

                            <div className="text-center mt-4">
                                <span>Partager mon score sur :</span>
                            </div>

                            {/* Boutons de partage */}
                            <div className="text-center mt-3">
                                <FacebookShareButton className="mx-1" url={shareUrl} quote={shareText} hashtag="#NCLC">
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>

                                <TwitterShareButton className="mx-1" url={shareUrl} title={shareText} hashtags={['NCLC', 'CANADA']} >
                                    <TwitterIcon size={32} round />
                                </TwitterShareButton>

                                <WhatsappShareButton className="mx-1" url={shareUrl} title={shareText} >
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                            </div>


                        </Form>

                    </Col>
                </Row>
            </Container>
        </LoadingOverlay>
    );
}

export default CalculatriceNcLc;

