
module.exports = {
    listCountries: function () {
        return [
            { code: 'CM', name: 'Cameroun', value: 'Cameroun' },
            { code: 'ML', name: 'Mali', value: 'Mali' },
            { code: 'CI', name: 'Côte d\'ivoire', value: 'Côte d\'ivoire' },
            { code: 'TD', name: 'Tchad', value: 'Tchad' },
            { code: 'CF', name: 'République Centrafricaine', value: 'République Centrafricaine' },
            { code: 'SN', name: 'Sénégal', value: 'Sénégal' },
            { code: 'GA', name: 'Gabon', value: 'Gabon' },
            { code: 'BJ', name: 'Bénin', value: 'Bénin' },
            { code: 'BF', name: 'Burkina Faso', value: 'Burkina Faso' },
            { code: 'CG', name: 'République du Congo', value: 'République du Congo' },
            { code: 'CD', name: 'RDC', value: 'RDC' }
        ].sort((a, b) => a.name - b.name);
    },

    getCryptoSecretKey: function () {
        return 'WXKDLN'
    },

    computeCRLCScore: function (sujet, points) {
        if (sujet === 'CO') {
            if (points < 331) return 'A1';
            if (points >= 331 && points <= 368) return 'A2';
            if (points >= 369 && points <= 397) return 'B1';
            if (points >= 398 && points <= 457) return 'B1';
            if (points >= 458 && points <= 502) return 'B2';
            if (points >= 503 && points <= 522) return 'B2';
            if (points >= 523 && points <= 548) return 'C1';
            if (points >= 549 && points <= 639) return 'C1';
            if (points >= 640 && points <= 699) return 'C2';
        }
        else if (sujet === 'CE') {

            if (points < 342) return 'A1';
            if (points >= 342 && points <= 374) return 'A2';
            if (points >= 375 && points <= 405) return 'B1';
            if (points >= 406 && points <= 452) return 'B1';
            if (points >= 453 && points <= 498) return 'B2';
            if (points >= 499 && points <= 523) return 'B2';
            if (points >= 524 && points <= 548) return 'C1';
            if (points >= 549 && points <= 639) return 'C1';
            if (points >= 640 && points <= 699) return 'C2';

        }
        return ''
    },

    getPoints: function (questionNumber, subjectCode) {

        if (questionNumber <= 4) return 3;
        if (questionNumber <= 8) return 5;
        if (questionNumber <= 11) return 9;
        if (questionNumber <= 16) return 15;
        if (questionNumber <= 20) return 19;
        if (questionNumber <= 28) return 21;
        if (questionNumber <= 34) return 26;
        if (questionNumber <= 39) return 33;

        return 0;
    },

    uploadFileToStorage: function (ref, uploadBytesResumable, getDownloadURL, storage, fichier, destPath, next) {

        if (fichier) {

            const storageRef = ref(storage, `${destPath}`);
            const uploadTask = uploadBytesResumable(storageRef, fichier);

            uploadTask.on('state_changed', (snapshot) => {
                const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            },
                (err) => {
                    next(err, null);
                },

                () => {
                    //obtenir le lien de téléchargement
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        next(null, url);
                    })
                })
        }
        else {
            next(null, undefined);
        }
    }

}