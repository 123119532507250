import React from 'react';
import Footer from '../components/Footer';
import Menu from '../components/Navbar';
import ResetPassword from '../components/ResetPassword';
import { useSearchParams, useNavigate, NavLink} from "react-router-dom";
import { useEffect } from 'react';


const P_ResetPassword = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    let oobCode = searchParams.get('oobCode') || null;
    let mode = searchParams.get('mode') || null;
    const [step, setStep] = React.useState(0);

    useEffect(() => {

        if (!oobCode || !mode) {
            navigate('/error');
            return;
        }

        if (mode !== 'resetPassword') {
            navigate('/error');
            return;
        }

    }, [oobCode, navigate, mode])


    return (
        <>
            <Menu />
            <div style={{ marginTop: '14.5rem' }}></div>
            {
                (step == 0) ? (
                    <ResetPassword oobCode={oobCode} setStep={setStep} />
                ) : (
                    <div className="card">
                        <div className="card-body p-4 p-sm-5">
                            <div className="text-center">
                                <h4 className='text-success'>Mot de passe réinitialisé avec succès</h4>
                                <p>
                                    La réinitialisation de votre mot de passe a été effectuée avec succès. Vous pouvez vous connecter en cliquant sur le lien suivant : 
                                </p>
                                <NavLink to={'/connexion'}>Connexion</NavLink>
                            </div>
                        </div>
                    </div>
                )
            }
            <div style={{ marginBottom: '5rem' }}></div>
            <Footer />
        </>
    );
};

export default P_ResetPassword;