import React from 'react';
import Alert from '@mui/material/Alert';


const ResultHeader = ({ correct, total, time, points, points_total, display, cecrl}) => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {
                (display === true) ? (
                    <>
                        <h5 className='mb-3 underline' style={{fontWeight: 'bolder'}}>Résultats de votre test</h5>
                        <h6>Vous avez répondu correctement à {correct} question(s) sur {total}</h6>
                        <h6 className='mb-3'>Votre temps est : {time} </h6>
                        <Alert severity="info">Vous avez atteint {points} point(s) sur {points_total}</Alert>
                        <h4 className='mt-3'><span style={{ backgroundColor: 'yellow' }}>Niveau CECRL: {cecrl} </span></h4>
                        <img decoding="async" loading="lazy" className="aligncenter size-full wp-image-3575 img_quest" srcSet="../assets/img/notation-tcf-canada.png" sizes="(max-width: 719px) 100vw, 719px" />
                    </>
                ) : (
                    null
                )
            }
        </>
    );
};

export default ResultHeader;