import React from 'react';
import TextWithBoldFormatting from './TextWithBoldFormatting';

function TextWithLineBreaks({ text }) {
    // Divise le texte en un tableau de lignes en utilisant '\n' comme séparateur
    const lines = text.split('\n');


    return (
        <div>
            {lines.map((line, index) => (
                <React.Fragment key={index}>
                    <TextWithBoldFormatting text={line} />
                    <br /> {/* Ajoute un saut de ligne entre les lignes */}
                </React.Fragment>
            ))}
        </div>
    );
}

export default TextWithLineBreaks;
