import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

const AudioPlayer = ({ exists, src }) => {


    const styles = {
        audioPlayer: {
            width: '100%',
            borderRadius: '0',
            background: '#f1f3f4',
            boxShadow: '0 2px 4px rgb(0, 0, 0, 0.1)'
        }
    }

    return (
        <>
            {
                (exists === true) ? (
                    <ReactAudioPlayer
                        src={src}
                        controls
                        controlsList="nodownload"
                        style={styles.audioPlayer}
                    />
                ) : null
            }
        </>
    );
};

export default AudioPlayer;