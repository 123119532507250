import * as React from "react";
import { auth } from "../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { findUserByEmail } from "../services/api";
import MyToast from './MyToast';
import MySpinner from './MySpinner';



const RecoverPassword = () => {

    const [email, setEmail] = React.useState('');
    const [inProgress, setinProgress] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!email || email?.length < 4) {
            setOpenToast({
                open: true,
                msg: "Veuillez entrer une adresse email valide",
                severity: "error"
            })
            return;
        }

        try {

            setinProgress(true);

            const exists = await findUserByEmail(email);

            if (!exists || exists.status !== 200) {
                setOpenToast({
                    open: true,
                    msg: "Une erreur s'est produite, veuillez réessayer ultérieurement",
                    severity: "error"
                })
                setinProgress(false);
                return;
            }

            if (exists && exists.data?.data) {

                await sendPasswordResetEmail(auth, email);
                setOpenToast({
                    open: true,
                    msg: "Le lien de rénitialisation vous a été envoyé par mail",
                    severity: "success"
                })

                setinProgress(false);
                setEmail('');
                return;
            }

            else {
                setOpenToast({
                    open: true,
                    msg: "Ce compte est introuvable",
                    severity: "error"
                })
                setinProgress(false);
                return;
            }

        } catch (error) {
            setinProgress(false);
            const errorCode = error?.code;
            const errorMessage = error?.message;

            if (errorCode == 'auth/email-already-in-use') {
                setOpenToast({
                    open: true,
                    msg: "Cette adresse email est déjà utilisée",
                    severity: "error"
                })
                return;
            }

            else if (errorCode === 'auth/network-request-failed') {
                setOpenToast({
                    open: true,
                    msg: "Vérifiez votre connexion internet",
                    severity: "error"
                })
                return;
            }

            else {
                setOpenToast({
                    open: true,
                    msg: "Une erreur s'est produite, veuillez réessayer ultérieurement",
                    severity: "error"
                })
                return;
            }
        }

    };


    return (
        <>
            <div className="row mx-0 px-0">
                <div className="col-sm-1 col-md-2 col-lg-3 col-xxl-4"></div>
                <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-4">

                    <div className="card">
                        <div className="card-body p-4 p-sm-5">
                            <h5 className="mb-0">Vous avez oublié votre mot de passe ?</h5><small>Entrer votre e-mail et nous allons vous envoyer un lien de réinitialisation.</small>
                            <form className="mt-4" onSubmit={handleSubmit}>

                                <div className="form-field animation a3">
                                    <label>Entrer votre adresse E-mail</label>
                                    <input type="email"
                                        name='email'
                                        className="w-100"
                                        id="email"
                                        onChange={e => setEmail(e.target.value)}
                                        required />
                                </div>

                                <div className="form-group mt-4">
                                    <button className="btn btn-block mt-3" type="submit" name="submit" style={{ backgroundColor: '#2b3035', color: 'white' }}>Envoyer le lien</button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>

            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />

        </>
    );
};

export default RecoverPassword;