import React from 'react';
import DisplaySubject from '../components/DisplaySubject';
import Container from 'react-bootstrap/Container';
import AudioPlayer from '../components/AudioPlayer';
import Menu from '../components/Navbar';
import QuestionImage from '../components/QuestionImage';
import QuestionGrille from '../components/QuestionGrille';
import SubjectTitle from '../components/SubjectTitle';
import Button from 'react-bootstrap/Button';
import LoadingOverlay from 'react-loading-overlay';
import Footer from '../components/Footer';
import Timer from '../components/Timer';
import ResultHeader from '../components/ResultHeader';
import { useSearchParams, useNavigate, NavLink } from 'react-router-dom';
import { getAppConfig, getSubject, listConstantes, verifyUserAccessToSubject } from '../services/api';
import { useAuth } from '../Contexts/AuthContext';
import { computeCRLCScore, getPoints } from '../services/util';


class Question {
    constructor(order, repondu, mode, points, trouve, position_rep, position_choisi) {
        this.order = order;
        this.repondu = repondu;
        this.mode = mode;
        this.points = points;
        this.trouve = trouve;
        this.position_rep = position_rep;
        this.position_choisi = position_choisi;
    }
}


const P_Subject_C = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const { user } = useAuth();
    const subjectID = searchParams.get('subject_id') || null;
    const subject_type = searchParams.get('subject_type') || null;

    const [numberOfQuestion, setNumberOfQuestion] = React.useState(39);
    //const [duration, setDuration] = React.useState(1);
    const [duration, setDuration] = React.useState((subject_type?.toUpperCase() === 'CO' ? 35 : (subject_type?.toUpperCase() === 'CE') ? 60 : 1));
    const [timeMode, setTimeMode] = React.useState('exams');
    const [subjectTypeID, setSubjectTypeID] = React.useState(null);
    const [display_result, setDisplay_result] = React.useState(false);
    const [step, setStep] = React.useState(0);
    const [questions, setQuestions] = React.useState([]);
    const [questions_rep, setQuestions_rep] = React.useState([]);
    const [subject, setSubject] = React.useState(null);
    const [currentQuestion, setCurrentQuestion] = React.useState(1);
    const [currentTime, setCurrentTime] = React.useState('');
    const [imgLink, setImgLink] = React.useState('image_base_url');
    const [loading, setLoading] = React.useState(true);
    const [score, setScore] = React.useState({});
    const [loading1, setLoading1] = React.useState(false);

    const [appConfig, setAppConfig] = React.useState(null);


    React.useEffect(() => {

        if (!subjectID || !subject_type) {
            navigate('/error');
            return;
        }

        const subjectTypeID_tmp = listConstantes()[subject_type?.toString().trim().toUpperCase()]['id'];

        if (!subjectTypeID_tmp) {
            navigate('/error');
            return;
        }

        setSubjectTypeID(subjectTypeID_tmp);

        setLoading(true);

        //vérifier l'autorisation
        verifyUserAccessToSubject({ userID: user?.uid, subjectID }).then(resp => {
            const authorize = resp.data.data;
            //console.log(resp.data);
            if (authorize === false) {
                setLoading(false);
                navigate('/non-autorise');
                return;
            }
            else {
                getSubject({ subjectID }).then(response => {
                    const tmp = response.data.data;

                    if (!tmp) {
                        setLoading(false);
                        navigate('/not-found');
                        return;
                    }

                    setLoading(false);
                    setSubject(tmp);
                    setQuestions(tmp?.questions);

                }).catch(err => {
                    setLoading(false);
                    navigate('/error');
                    return;
                })
            }
        }).catch(err => {
            setLoading(false);
            navigate('/error');
            return;
        })

    }, [subjectID, navigate])




    React.useEffect(() => {
        setLoading1(true);
        getAppConfig().then(resp => {
            setAppConfig(resp.data.data || {
                pays: ['CM'],
                img_src: 'google'
            })

            setLoading1(false);

        }).catch(err => {
            setLoading1(false);
        })

    }, [])
    

    React.useEffect(() => {

        if (subject_type.toUpperCase() === 'CE') {
            if (appConfig?.img_src === 'google') {
                setImgLink('image_current_url');
            }

            if (appConfig?.img_src === 'aws') {
                setImgLink('image_s3_url');
            }
        }


    }, [appConfig])


    React.useEffect(() => {

        if (questions && questions?.length !== 0) {
            initQuestionnaire();
        }

    }, [questions])


    React.useEffect(() => {
        if (step === 0) {
            setDisplay_result(false);
        }
    }, [step])


    React.useEffect(() => {

        if ((currentTime === '0' || currentTime === 0) && timeMode === 'exams') {
            computeScore();
            setStep(1);
            setTimeMode('result');
            setDisplay_result(true);
        }

    }, [currentTime])


    //#dd3333
    //#81d742


    const handleShowResults = e => {
        e.preventDefault();
        computeScore();
        setStep(2);
    }


    React.useEffect(() => {
        if(step === 1) {
            setLoading(true);
            window.scrollTo(0, 0);
            setTimeout(stopLoading, 2000);
        }
    }, [step])


    const stopLoading = () => {
        setLoading(false);
    }

    const initQuestionnaire = () => {

        const tmp = [];

        for (let index = 1; index <= numberOfQuestion; index++) {
            const quest = new Question();
            quest.order = index;
            quest.repondu = false;
            quest.trouve = false;
            quest.mode = 'exams';

            //à mettre à jour
            quest.points = getPoints(index, subject_type.toUpperCase());
            quest.position_rep = questions[index - 1]?.response;


            tmp.push(quest);
        }

        setQuestions_rep(tmp);
    }

    const handleEndTest = e => {
        e.preventDefault();

        //il faut vérifier que toutes les questions ont été répondues correctement
        /*  for(const q of questions_rep) {
             if(q.repondu === undefined || q.repondu === false) {
                 alert('Vous devez répondre à toutes les questions avant de pouvoir terminer le quiz');
                 return;
             }
         } */
        computeScore();
        setStep(1);
        setTimeMode('result');
        setDisplay_result(true);
    }


    const handleNextQuestion = e => {
        e.preventDefault();

        //vérifions que l'utilisateur a répondu à la question
        const question = questions_rep[currentQuestion - 1];
        if (question.repondu === undefined || question.repondu === false) {
            alert('Vous devez choisir une réponse');
            return;
        }

        setCurrentQuestion(currentQuestion + 1);
    }



    const computeScore = () => {

        setScore({});

        let _score = {
            questions_trouves: 0,
            temps: `${Math.floor(((parseInt(duration) * 60) - parseInt(currentTime)) / 60)} Min et ${((parseInt(duration) * 60) - parseInt(currentTime)) % 60} Sec`,
            totalPoints: 0,
            points: 0,
            cecrl: undefined,
        }


        for (const ques of questions_rep) {
            _score.totalPoints = _score.totalPoints + ques.points;
            _score.points = _score.points + (ques.trouve === true ? ques.points : 0);
            _score.questions_trouves = _score.questions_trouves + (ques.trouve === true ? 1 : 0);
        }

        _score.cecrl = computeCRLCScore('CO', _score.points);

        setScore(_score);
    }


    const getOptions = q => {
        return [
            { alpha: 'A', label: q?.option1 },
            { alpha: 'B', label: q?.option2 },
            { alpha: 'C', label: q?.option3 },
            { alpha: 'D', label: q?.option4 }
        ]
    }

    return (
        <>
            <Menu />
            <LoadingOverlay
                active={loading1}
                spinner
                className='fs-md-0 fs--1'
                text=''>
                <LoadingOverlay
                    active={loading}
                    spinner
                    className='fs-md-0 fs--1'
                    text=''>

                    {/* <div className='row justify-content-center align-items-center text-center'>
                    <Spinner animation="border" role="status" className={`${loading === true ? 'my-2' : 'd-none'}`}></Spinner>
                </div> */}
                    <div style={{ marginTop: '9.5rem' }}></div>
                    <SubjectTitle
                        img={"../assets/img/cover-question1.jpg"}
                        title={`${(listConstantes()[subject_type.toUpperCase()]['name'])?.toUpperCase()} SUJET ${subject?.subjectNumber} ${subject?.serieNumber ? ` (Série ${subject?.serieNumber})`.toLowerCase() : ''}`}
                    />
                    <div style={{ marginTop: '2rem' }}></div>
                    <Container className='mt-6'>
                        <ResultHeader
                            correct={score.questions_trouves}
                            total={numberOfQuestion}
                            time={score.temps}
                            points={score.points}
                            points_total={score.totalPoints}
                            cecrl={score.cecrl}
                            display={display_result}
                        />

                        {
                            (step === 1) ? (
                                <div className='d-flex justify-content-center my-4'>
                                    <Button variant="primary " className='mr-2 mr-md-3 mr-xl-3' style={{ fontWeight: 'bolder' }} onClick={e => window.location.reload()}>Reprendre le test</Button>
                                    <Button variant="dark" style={{ fontWeight: 'bolder' }} onClick={handleShowResults}>Voir les réponses</Button>
                                </div>
                            ) : null
                        }

                        {
                            (step === 0 || step === 2) ? (
                                <QuestionGrille
                                    currentQuestion={currentQuestion}
                                    mode={timeMode}
                                    setCurrentQuestion={setCurrentQuestion}
                                    questions_rep={questions_rep}
                                />
                            ) : null
                        }


                        {
                            (step === 0 || step === 2) ? (
                                questions.map((quest, index) => (
                                    <>

                                        {
                                            ((currentQuestion === index + 1) || step === 2) ? (
                                                <>
                                                    <div style={{ marginTop: '4rem' }}></div>
                                                    <QuestionImage
                                                        exists={(quest[imgLink] && quest[imgLink] !== "") ? true : false}
                                                        src={quest[imgLink]}
                                                        srcSet={""}
                                                    />

                                                    <h4 className='font-weight-extra-bold mt-2' id={`question${index + 1}`}> Question {index + 1}: </h4>

                                                    <AudioPlayer
                                                        src={quest?.audio_current_url}
                                                        exists={(quest.audio_current_url && quest.audio_current_url !== "") ? true : false}
                                                    />

                                                    <div className='mb-2'></div>

                                                    <div className='d-flex justify-content-center my-4'>
                                                        <h6 style={{ textDecoration: 'underline' }}>
                                                            {subject_type.toUpperCase() === 'CO' ? (<>Écoutez le document sonore et la question.</>) : (<>Lisez le texte de l'image et la question.</>)} <br /> Choisissez la bonne réponse
                                                        </h6>
                                                    </div>

                                                    <div className='mb-6'></div>

                                                    <DisplaySubject
                                                        order={index + 1}
                                                        options={getOptions(quest)}
                                                        mode={timeMode}
                                                        questions_rep={questions_rep}
                                                        setQuestions_rep={setQuestions_rep}
                                                    />

                                                </>
                                            ) : null
                                        }


                                    </>
                                ))

                            ) : null
                        }

                        {
                            (step === 0) ? (
                                <>
                                    <div className="d-flex mb-2 mt-5">
                                        <Button className="p-2 font-weight-extra-bold" disabled={currentQuestion === 1} onClick={e => setCurrentQuestion(currentQuestion - 1)} size="sm" variant="dark">Question précédente</Button>

                                        {
                                            (currentQuestion < numberOfQuestion) ? (
                                                <Button className="ml-auto p-2 font-weight-extra-bold" size="sm" variant="primary" onClick={handleNextQuestion}>Question suivante</Button>
                                            ) : (
                                                <Button className="ml-auto p-2 font-weight-extra-bold" size="sm" variant="primary" onClick={handleEndTest} >Terminer le quiz</Button>
                                            )
                                        }

                                    </div>
                                    <div style={{ marginTop: '8rem' }}></div>
                                </>
                            ) : null
                        }

                        {
                            (step === 2) ? (
                                <>
                                    <div className='d-flex justify-content-center my-4'>
                                        <Button variant="primary" style={{ fontWeight: 'bolder' }} onClick={e => window.location.reload()}>Reprendre le test</Button>
                                    </div>
                                    <div style={{ marginTop: '8rem' }}></div>
                                </>
                            ) : null
                        }

                        <Timer
                            duration={duration}
                            timeMode={timeMode}
                            setCurrentTime={setCurrentTime}
                        />

                    </Container>

                    <div style={{ marginTop: '2rem' }}></div>

                    <Footer />
                </LoadingOverlay>
            </LoadingOverlay>
        </>
    );
};

export default P_Subject_C;