import React from 'react';
import CalculatriceNcLc from '../components/CalculatriceNcLc';
import Footer from '../components/Footer';
import Menu from '../components/Navbar';


const P_CalculatriceNcLc = () => {

    return (
        <>
            <Menu />
            <div style={{ height: '14rem', backgroundColor: "#F5F5F5"}}></div>
            <CalculatriceNcLc />
            <div style={{ height: '9rem', backgroundColor: "#F5F5F5" }}></div>
            <Footer />
        </>
    );
};

export default P_CalculatriceNcLc;