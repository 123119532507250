import React from 'react';
import { WindmillSpinnerOverlay } from 'react-spinner-overlay'

const MySpinner = (props) => {

    let loading = props.loading;
    let msg = (props.msg == undefined) ? '' : props.msg;


    return (
        <>
            {/* <FerrisWheelSpinner loading={loading} size={80} /> */}
            <WindmillSpinnerOverlay
                loading={loading}
                overlayColor="rgba(0,153,255,0.2)"
                message={msg}
                borderWidth={8}
                borderHeight={12}
                borderRadius={10}
                size={70}
                className='text-white z-index-250'
            />
        </>
    );
};

export default MySpinner;