import React from 'react';
import Footer from '../components/Footer';
import Menu from '../components/Navbar';
import Spinner from 'react-bootstrap/Spinner';
import { NavLink, useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Container from 'react-bootstrap/Container';
import { useAuth } from '../Contexts/AuthContext';
import { listSubscriptionsHistory } from '../services/api';


const P_SubscriptionHistory = () => {

    const navigate = useNavigate();
    const { user } = useAuth();

    const [histories, setHistories] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        listSubscriptionsHistory({ userID: user?.uid})
            .then(resp => {
                if (resp && resp?.data && resp?.data?.data)
                    setHistories(resp?.data?.data);

                setLoading(false);

            }).catch(err => {
                console.log(err);
                setLoading(false)
            })
    }, [])


    const formatDate = date => {
        const date_ = new Date(date);
        const annee = date_.getFullYear().toString();
        const mois = (date_.getMonth() + 1).toString().padStart(0, 2);
        const jour = date_.getDate().toString().padStart(2, '0');

        return `${annee}/${mois.length === 1 ? '0'+ mois : mois}/${jour.length === 1? '0' + jour : jour}`;
    }

    const getStatus = status => {

        let rep = <Chip label="En cours" size="small" color="success" variant="outlined" />

        switch (status) {
            case "UPDATED":
                rep = <Chip label="Remplaçé" size="small" color="warning" variant="outlined" />
                break;

            case "DISABLED":
                rep = <Chip label="Expiré" size="small" color="error" variant="outlined" />
                break;

            default:
                break;
        }

        return rep;
    }

    return (
        <>
            <Menu />
            <div style={{ marginTop: '14.5rem' }}></div>

            <Container className=''>
                <div className='row justify-content-center align-items-center text-center'>
                    <Spinner animation="border" role="status" className={`${loading === true ? '' : 'd-none'}`}></Spinner>
                </div>
                <div className="card mb-3">
                    <div className="card-header">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-6 col-sm-auto d-flex align-items-center pr-0">
                                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Vos derniers abonnements</h5>
                            </div>
                        </div>
                    </div>

                    <div className="card-body px-0 pt-0">
                        <div className="dashboard-data-table">
                            <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="row mx-1"><div className="col-sm-12 col-md-6 px-3" /><div className="col-sm-12 col-md-6 px-3" /></div><div className="table-responsive"><table className="table table-sm table-dashboard fs--1 data-table border-bottom dataTable no-footer" data-options="{&quot;responsive&quot;:false,&quot;pagingType&quot;:&quot;simple&quot;,&quot;lengthChange&quot;:false,&quot;searching&quot;:false,&quot;pageLength&quot;:8,&quot;columnDefs&quot;:[{&quot;targets&quot;:[0,6],&quot;orderable&quot;:false}],&quot;language&quot;:{&quot;info&quot;:&quot;_START_ to _END_ Items of _TOTAL_ — <a href=\&quot;#!\&quot; class=\&quot;font-weight-semi-bold\&quot;> view all <span class=\&quot;fas fa-angle-right\&quot; data-fa-transform=\&quot;down-1\&quot;></span> </a>&quot;},&quot;buttons&quot;:[&quot;copy&quot;,&quot;excel&quot;]}" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
                                <thead className="bg-200 text-900">
                                    <tr role="row">
                                        <th className="sort pr-1 align-middle sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="" style={{ width: '200.734px' }}>Offre</th>
                                        <th className="sort pr-1 align-middle sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="" style={{ width: '100.734px' }}>Validité</th>
                                        <th className="sort pr-1 align-middle sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="" style={{ width: '100.734px' }}>Prix</th>
                                        <th className="sort pr-1 align-middle" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="" style={{ width: '200.734px' }}>Date début</th>
                                        <th className="sort pr-1 align-middle" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="" style={{ width: '200.734px' }}>Date fin</th>
                                        <th className="sort pr-1 align-middle " tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-label="" style={{ width: '107.016px' }}>Statut</th>
                                    </tr>
                                </thead>

                                <tbody id="subscriptions">
                                    {
                                        histories.map((subs) => (
                                            <tr className="btn-reveal-trigger odd" role="row">
                                                <th className="align-middle">
                                                    <NavLink to='/accueil' >{subs.offer?.name}</NavLink>
                                                </th>
                                                <td className="align-middle">{subs.offer?.duration} jour(s)</td>
                                                <td className="align-middle">${subs.offer?.price}</td>
                                                <td className="align-middle">
                                                    {formatDate(subs.startDate)}
                                                </td>
                                                <td className="align-middle">
                                                    {formatDate(subs.endDate)}
                                                </td>
                                                <td className="align-middle">
                                                    {getStatus(subs.subscriptionStatus)}
                                                </td>

                                            </tr>
                                        ))
                                    }

                                </tbody>
                            </table>
                            </div>

                            </div>
                        </div>
                    </div>
                </div>

            </Container>

            
            <div style={{ marginBottom: '5rem' }}></div>
            <Footer />
        </>
    );
};

export default P_SubscriptionHistory;