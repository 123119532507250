import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';

function AdminMenu() {

    const navigate = useNavigate();

    return (
        <>
            <Navbar collapseOnSelect expand="md" className="bg-body-tertiary" fixed="top" bg="dark" data-bs-theme="dark" id="myNav">
                <Container className='px-4 '>
                    <Navbar.Brand href="#home text-light" onClick={(e) => navigate('/accueil')} >EXAMS-TCFCANADA</Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">

                        <Nav className="me-auto text-light">
                            <Nav.Link className="text-light" href="#" onClick={(e) => navigate('/administration?service=0')}>Dashbord</Nav.Link>
                            <Nav.Link className="text-light" href="#" onClick={(e) => navigate('/administration?service=1')}>Ajouter un sujet</Nav.Link>
                            <Nav.Link className="text-light" href="#" onClick={(e) => navigate('/administration?service=2')}>Créer une offre</Nav.Link>
                            <Nav.Link className="text-light" href="#" onClick={(e) => navigate('/administration?service=3')}>Supprimer une offre</Nav.Link>
                            <Nav.Link className="text-light" href="#" onClick={(e) => navigate('/administration?service=4')}>Coupons</Nav.Link>
                            <Nav.Link className="text-light" href="#" onClick={(e) => navigate('/administration?service=5')}>Envoyer une notif</Nav.Link>
                        </Nav>

                    </Navbar.Collapse>

                </Container>
            </Navbar>

        </>
    );
}

export default AdminMenu;
