import React from 'react';
import Pagination from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';
import LegendSquare from './LegendSquare';


const QuestionGrille = ({ questions_rep, currentQuestion, mode, setCurrentQuestion }) => {

    const [customPaginationItem, setCustomPaginationItem] = React.useState(null);

    const handleChange = (e, val) => {
        setCurrentQuestion(val);
    }

    React.useEffect(() => {
        const tmp = (
            <Pagination
                count={39}
                variant="outlined"
                color="secondary"
                shape={"rounded"}
                hideNextButton={true}
                hidePrevButton={true}
                size="large"
                onChange={handleChange}
                page={currentQuestion}
                boundaryCount={39}
                className='mb-2'
                renderItem={(item) => (
                    <PaginationItem
                        type='button'
                        {...item}
                        style={getStyle(item.page, mode, currentQuestion, questions_rep)}
                    />
                )}
            />
        )

        setCustomPaginationItem(tmp);

    }, [currentQuestion, mode])


    const getStyle = (order, mode, currentQuestion, questions_rep) => {


        if (mode === 'exams') {

            //question courante
            if (currentQuestion === order) {
                return {
                    backgroundColor: '#824D3D',
                    color: 'white'
                }
            }

            //question répondue
            else if (questions_rep[order - 1]?.repondu === true) {
                return {
                    backgroundColor: '#333333',
                    color: 'white'
                }
            }

            //couleurs par défaut
            else {
                return {
                    backgroundColor: '#fff',
                    color: 'black'
                }
            }

        }

        //mode correction
        else {
            //question trouvée
            if (questions_rep[order - 1]?.trouve === true) {
                return {
                    backgroundColor: '#81d742',
                    color: 'white'
                }
            }
            //question ratée ou non répondue
            else {
                return {
                    backgroundColor: '#dd3333',
                    color: 'white'
                }
            }
        }
    }

    return (
        <>
            <div className='w-100 h-auto grille'>
                {customPaginationItem}
            </div>
            <div className='d-flex justify-content-center my-4'>
                <LegendSquare
                    color={'#fff'}
                    label={'Non répondue'}
                    visible={mode === 'exams' ? true : false}
                    key={'nonrep'}
                />

                <LegendSquare
                    color={'#333333'}
                    label={'Répondue'}
                    visible={mode === 'exams' ? true : false}
                    key={'rep'}
                />

                <LegendSquare
                    color={'#824D3D'}
                    label={'En cours'}
                    visible={mode === 'exams' ? true : false}
                    key={'encours'}
                />

                <LegendSquare
                    color={'#81d742'}
                    label={'Trouvée'}
                    visible={mode !== 'exams' ? true : false}
                    key={'trouve'}
                />

                <LegendSquare
                    color={'#dd3333'}
                    label={'Ratée'}
                    visible={mode !== 'exams' ? true : false}
                    key={'rate'}
                />

            </div>
        </>
    );
};

export default QuestionGrille;