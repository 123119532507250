import React from 'react';
import Footer from '../components/Footer';
import Menu from '../components/Navbar';
import { NavLink } from 'react-router-dom';

const P_Error = () => {

    return (
        <>
            <Menu />
            <div style={{ marginTop: '12.5rem' }}></div>
            <div className="row mx-0 px-0">
                <div className="col-sm-1 col-md-2 col-lg-3 col-xxl-4"></div>
                <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-4">
                    <div className="card">
                        <div className="card-body p-4 p-sm-5">
                            <div className="display-1 text-300 fs-error d-flex justify-content-center">500</div>
                            <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold d-flex justify-content-center"><h5>Oups, quelque chose à mal fonctionné !</h5></p>
                            <hr />
                            <p>Essayez de rafraîchir la page, ou de retourner en arrière et retenter l'action une nouvelle fois. Si le problème persiste, <NavLink to={'/contactez-nous'}>Contactez-nous. </NavLink> </p>
                        </div>
                    </div>

                </div>
            </div>
            <div style={{ marginBottom: '5rem' }}></div>
            <Footer />
        </>
    );
};

export default P_Error;