import React from 'react';


function getStyle(color) {
    return {
        width: '10px',
        height: '12px',
        margin: '7px',
        display: 'inline-block',
        border: '1px solid black',
        backgroundColor: color
    }
}


const LegendSquare = ({ visible, label, color }) => {

    return (
        <>
            {
                visible === true ? (
                    <>
                        <div style={getStyle(color)}></div>
                        <span className='mr-2 pt-1 pt-sm-1 pt-md-0'>{label}</span>
                    </>
                ) : null
            }
        </>
    );
};

export default LegendSquare;