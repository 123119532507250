import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MailIcon from '@mui/icons-material/Mail';
import { useParams } from 'react-router-dom';
import Login from '../components/Login';
import Menu from '../components/Navbar';
import PhoneLogin from '../components/PhoneLogin';
import Footer from '../components/Footer';


const P_Login = () => {

    let { method } = useParams();
    const [radioValue, setRadioValue] = React.useState('email');


    React.useEffect(() => {

        if (!method) {
            method = 'email'
            setRadioValue(method);
        }
        else if (method != 'email' && method != 'phone') {
            method = 'email';
            setRadioValue(method);
        }
        else {
            setRadioValue(method);
        }

    }, [])

    const handleChange = e => {
        e.preventDefault();
        const choix = e.currentTarget.value;
        window.location.href = `/connexion/${choix}`;
    }

    return (
        <div className='page pt-5 pb-6 h-100'>
            <Menu />
            <div style={{ marginTop: '12.5rem' }}></div>
            {/* <div className="row mx-0 px-0 mb-3">
                <div className="col-sm-1 col-md-2 col-lg-3 col-xxl-4"></div>
                <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-4">
                    <h6 style={{ fontWeight: '', color: 'black' }}>Choisir la méthode de connexion</h6>
                    <ButtonGroup className="fs--1 col-12 px-0 mt-1">
                        <ToggleButton
                            key={'email'}
                            id={`email`}
                            type="radio"
                            variant="outline-success"
                            name="radio"
                            value={'email'}
                            checked={radioValue === 'email'}
                            onChange={handleChange}
                        >
                            <MailIcon /> Email
                        </ToggleButton>

                        <ToggleButton
                            key={'phone'}
                            id={`phone`}
                            type="radio"
                            variant="outline-success"
                            name="radio"
                            value={'phone'}
                            checked={radioValue === 'phone'}
                            onChange={handleChange}
                        >
                            <PhoneIphoneIcon /> Phone
                        </ToggleButton>

                    </ButtonGroup>

                </div>
            </div> */}

            {
                (radioValue === 'email') ? (
                    <Login />
                ) : null
            }

            {/* {
                (radioValue === 'phone') ? (
                    <PhoneLogin />
                ) : null
            }
 */}
            <div style={{ paddingBottom: '5rem' }}></div>
            <Footer />
        </ div>
    );
};

export default P_Login;