import React from 'react';
import Footer from '../components/Footer';
import RecoverPassword from '../components/RecoverPassword';
import Menu from '../components/Navbar';

const P_PasswordRecover = () => {
    return (
        <>
            <Menu />
            <div style={{ marginTop: '14.5rem' }}></div>
            <RecoverPassword />
            <div style={{ marginBottom: '5rem' }}></div>
            <Footer />
        </>
    );
};

export default P_PasswordRecover;