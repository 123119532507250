import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { auth } from "../firebase";
import { confirmPasswordReset } from "firebase/auth";
import MyToast from './MyToast';
import MySpinner from './MySpinner';

const ResetPassword = ({ oobCode, setStep}) => {

    const navigate = useNavigate();

    const [password, setPassword] = React.useState('');
    const [cfpassword, setcfPassword] = React.useState('');
    const [inProgress, setinProgress] = React.useState(false);
    const [openToast, setOpenToast] = React.useState({
        open: false,
        msg: '',
        severity: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!password || !cfpassword) {
            setOpenToast({
                open: true,
                msg: "Veuillez renseigner tous les champs !",
                severity: "error"
            })
            return;
        }

        if (password.length < 6) {
            setOpenToast({
                open: true,
                msg: "Le mot de passe doit voir au moins 6 caractères",
                severity: "error"
            })
            return;
        }

        if (password !== cfpassword) {
            setOpenToast({
                open: true,
                msg: "Les mots de passe ne correspondent pas",
                severity: "error"
            })
            return;
        }

        if (!oobCode || !password) {
            setOpenToast({
                open: true,
                msg: "Une erreur s'est produite; Veuillez réessayer ultérieurement",
                severity: "error"
            })
            return;
        }

        try {

            setinProgress(true);

            await confirmPasswordReset(auth, oobCode, password);

            setOpenToast({
                open: true,
                msg: "Votre mot de passe a été réinitialisé avec success",
                severity: "success"
            })
            setinProgress(false);

            setStep(1);

            return;

        } catch (error) {
            setinProgress(false);
            const errorCode = error?.code;
            const errorMessage = error?.message;

            console.log(errorCode, errorMessage);

            if (errorCode == 'auth/email-already-in-use') {
                setOpenToast({
                    open: true,
                    msg: "Cette adresse email est déjà utilisée",
                    severity: "error"
                })
                return;
            }

            else if (errorCode === 'auth/network-request-failed') {
                setOpenToast({
                    open: true,
                    msg: "Vérifiez votre connexion internet",
                    severity: "error"
                })
                return;
            }

            else {
                setOpenToast({
                    open: true,
                    msg: "Une erreur s'est produite, veuillez réessayer ultérieurement",
                    severity: "error"
                })
                return;
            }
        }

    };

    return (
        <>
            <div className="row mx-0 px-0">
                <div className="col-sm-1 col-md-2 col-lg-3 col-xxl-4"></div>
                <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-4">

                    <div className="card">
                        <div className="card-body p-4 p-sm-5">
                            <h5 className="text-center">Réinitialisation du mot de passe</h5>
                            <form className="mt-4" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input className="form-control" type="password" value={password} onChange={e => setPassword(e.target.value)} name="password" id="password" placeholder="Nouveau mot de passe" />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" type="password" value={cfpassword} onChange={e => setcfPassword(e.target.value)} name="cfpassword" id="cfpassword" placeholder="Confirmer le mot de passe" />
                                </div>
                                <button className="btn btn-block mt-3" type="submit" name="submit" style={{backgroundColor: '#2b3035', color: 'white'}}>Réinitialiser le mot de passe</button>
                            </form>
                        </div>
                    </div>

                </div>
            </div>

            <MyToast
                openToast={openToast}
                setOpenToast={setOpenToast}
            />

            <MySpinner loading={inProgress} />

        </>
    );
};

export default ResetPassword;