import React from 'react';

const CustomTitle = ({part1, part2, part3, description}) => {

    return (
        <div className='row mt-4 justify-content-center align-items-center text-center'>
            <div className="elementor-widget-container">
                <h2 className="elementor-heading-title elementor-size-default">{part1}<span style={{ color: "#135ef2" }}>{part2}</span>{part3}</h2>
            </div>
            <h6>{description}</h6>
        </div >
    );
};

export default CustomTitle;